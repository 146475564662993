import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import {UserTypeMap} from '../../constants';
import { Autocomplete } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import { useGridRegisterStrategyProcessor } from '@mui/x-data-grid/hooks/core/strategyProcessing';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';

const styles  = (theme) => ({
    pageMargin: {
        marginLeft: 275,
        marginTop: theme.spacing(3),
    },
    formControl: {
        marginLeft: 10,
        marginTop: theme.spacing(1),
        maxWidth: 200,
    },
    classSelector: {
        marginLeft: 10,
    },
    group: {
        marginLeft: 10,
    },
    schoolSelector: {
        marginLeft: 10,
        marginTop: theme.spacing(1),
    },
    academicYear: {
        marginLeft: 10,
        marginTop: theme.spacing(1),
        maxWidth: 200,
    },
    implementationTerm: {
        marginLeft: 10,
        marginTop: theme.spacing(1),
    },
    inputName: {
        margin: theme.spacing(1),
    },
    inputEmail: {
        margin: theme.spacing(1),
    },
    statusSelector: {
        marginLeft: 10,
        maxWidth: 200,
    },
    numberOfClasses: {
        marginLeft: 10,
        marginTop: theme.spacing(1),
    },
    button: {
        marginRight: 15,
        marginLeft: 10,
    },
    autoComplete: {
        "& div.MuiAutocomplete-endAdornment": {
            top: 'auto' // fix for the end adornment positioning too low
        }
    }

});

class UserEditor extends React.Component{

    constructor(props) {

        super(props);

        // List of fields in the form, for use with validation
        this.allFormFieldNames = ['auth_level','first_name','last_name','email',
        'gender','group','status','consent', 'school',
        'add_to_class_id', 'generate_classes_academic_year','generate_classes_implementation_term','generate_classes_number_of_classes'
        ];

        this.userId = props.match.params.userId ?? null; // The :userId parameter from the url path

        this.maximumTextFieldLength = 100;

        this.state = {

            // Tracks which form fields have validation errors, and the helper message to display
            formErrors: {
                auth_level: { error: false, helperText: '' },
                first_name: { error: false, helperText: '' },
                last_name: { error: false, helperText: '' },
                email: { error: false, helperText: '' },
                password_reset_button: { error: false, helperText: '' },
                gender: { error: false, helperText: '' },
                group: { error: false, helperText: '' },
                status: { error: false, helperText: '' },
                consent: { error: false, helperText: '' },
                add_to_class_id: { error: false, helperText: '' },
                generate_classes_academic_year: { error: false, helperText: '' },
                generate_classes_implementation_term: { error: false, helperText: '' },
                generate_classes_number_of_classes: { error: false, helperText: '' },
                save_button: { error: false, helperText: '' },
                school: { error: false, helperText: '' }
            },
            classOptions: [],
            userGroups: [],
            untaintedUser: {
                _id: '',
                first_name: '',
                last_name: '',
                email: '',
                auth_level: props.auth.user.access === 2 ? 1 : '', // If the logged-in user is a teacher default this to Student
                group: '',
                status: true,
                gender: '',
                consent: true,
                add_to_class_id: '',
                generate_classes_academic_year: '',
                generate_classes_implementation_term: '',
                generate_classes_number_of_classes: '',
                school: '',
            },
            user: {
                _id: '',
                first_name: '',
                last_name: '',
                email: '',
                auth_level: props.auth.user.access === 2 ? 1 : '', // If the logged-in user is a teacher default this to Student
                group: '',
                status: true,
                gender: '',
                consent: true,
                add_to_class_id: '',
                generate_classes_academic_year: '',
                generate_classes_implementation_term: '',
                generate_classes_number_of_classes: '',
                school: '',
            },
            usersClasses: [],
            successMessage: '',
            passwordResetSuccessMessage: '',
            schools: []
        };

    }

    // Return a formatted list of groups users can be in, for use in an Autocomplete component
    getGroupAutocompleteOptions = () => {
        return this.state.userGroups;
    }

    getGenders = () => {
        return ["Female","Male","Other","Prefer not to say"];
    }

    // [{ value: 2021, human_readable: "2021-2022"},...]
    getAcademicYears = () => {

        const startYear = 2021;
        const endYear = new Date().getFullYear() + 1;

        let academicYears = [];

        for(let i = startYear; i <= endYear; i++)
        {
            academicYears.push({ value: i, human_readable: i + '-' + (i+1)});
        }

        // Return in descending order
        return academicYears.reverse();
    }

    getTerms = () => {
        return ["Spring","Fall"];
    }

    // Event handler for when form fields change, so we can update state
    handleFieldChange = (event => {

        let fieldName = '';

        // Determine the name of the field from the name or id of the form element
        if(event.target.name !== undefined && event.target.name !==''){
            fieldName = event.target.name;
        }
        else if(event.target.id !== undefined && event.target.id)
        {
            fieldName = event.target.id;
        }
        else
        {
            console.log('Missing required name or id for field');
        }

        let user = this.state.user;

        switch(fieldName)
        {

            case 'status':
                user[fieldName] = (event.target.value === 'Active'); // status is boolean
                this.setState({user: user});
            break;
            case 'consent':
                user[fieldName] = event.target.checked; // status is boolean
                this.setState({user: user});
            break;
            default:

            user[fieldName] = event.target.value;
            this.setState({user: user});
            break;
        }


    });

    // Checks if the given value is valid for the given form field name,
    // and updates the state of formErrors to set/clear an error for the field
    isValueValidForField = (value, fieldName) => {

        let formErrors = this.state.formErrors;
        let fieldValue = value;

        let hasError = false;

        switch(fieldName)
        {
            case 'auth_level':
            case 'first_name':
            case 'last_name':
            case 'email':
            case 'status':

               if(fieldValue === '')
               {
                    formErrors[fieldName].error = true;
                    formErrors[fieldName].helperText = "This field is required";
                    hasError = true;
               }
               else
               {
                    formErrors[fieldName].error = false;
                    formErrors[fieldName].helperText = "";
               }

               break;
               default:
                break;
        }

        // Exit early if we encountered a validation error
        if(hasError)
        {
            this.setState({formErrors: formErrors});
            return false;
        }

        switch(fieldName)
        {
            case 'email':

                if(!/^\S+@\S+\.\S+$/.test(fieldValue))
                {
                    formErrors.email.error = true;
                    formErrors.email.helperText = "Invalid email address format"
                    hasError = true;
                }
                else
                {
                    formErrors.email.error = false;
                    formErrors.email.helperText = "";
                }

                break;
            case 'group':

                if(parseInt(this.state.user.auth_level) === 3 && fieldValue)
                {
                    formErrors.group.error = true;
                    formErrors.group.helperText = 'Researchers cannot be assigned to a Group.';
                    hasError = true;
                }
                else
                {
                    formErrors.group.error = false;
                    formErrors.group.helperText = '';
                }

                break;
            case 'add_to_class_id':
                // If creating a student and not given a value
                if(!this.state.user._id && parseInt(this.state.user.auth_level) === 1 && !fieldValue)
                {
                    formErrors.add_to_class_id.error = true;
                    formErrors.add_to_class_id.helperText = 'A class is required';
                    hasError = true;
                }
                break;
            case 'generate_classes_number_of_classes':
                if(fieldValue !== '' && isNaN(parseInt(fieldValue)))
                {
                    formErrors.generate_classes_number_of_classes.error = true;
                    formErrors.generate_classes_number_of_classes.helperText = 'Number of Classes must be an integer';
                    hasError = true;
                }
                else
                {
                    formErrors.generate_classes_number_of_classes.error = false;
                    formErrors.generate_classes_number_of_classes.helperText = '';
                }
                break;
            case 'generate_classes_academic_year':
                if(!isNaN(parseInt(this.state.user.generate_classes_number_of_classes)) && parseInt(this.state.user.generate_classes_number_of_classes) > 0
                    && fieldValue === '')
                {
                    formErrors.generate_classes_academic_year.error = true;
                    formErrors.generate_classes_academic_year.helperText = 'This field is required when creating classes';
                    hasError = true;
                }
                else
                {
                    formErrors.generate_classes_academic_year.error = false;
                    formErrors.generate_classes_academic_year.helperText = '';
                }

                break;
                case 'generate_classes_implementation_term':
                    if(!isNaN(parseInt(this.state.user.generate_classes_number_of_classes)) && parseInt(this.state.user.generate_classes_number_of_classes) > 0
                        && fieldValue === '')
                    {
                        formErrors.generate_classes_implementation_term.error = true;
                        formErrors.generate_classes_implementation_term.helperText = 'This field is required when creating classes';
                        hasError = true;
                    }
                    else
                    {
                        formErrors.generate_classes_implementation_term.error = false;
                        formErrors.generate_classes_implementation_term.helperText = '';
                    }

                    break;
                    default:
                        break;
        }


        this.setState({formErrors: formErrors});

        // Return false if we had an error
        return !hasError;

    }

    validateAllFormFields = (event) => {

        let allAreValid = true;

        this.allFormFieldNames.forEach((fieldName) => {
            if(!this.isValueValidForField(this.state.user[fieldName], fieldName))
            {
                allAreValid = false;
            }
        });

        return allAreValid;

    }

    // Event handler for when a form field has changed/blurred and should be validated
    blurOrChangeValidate = (event) => {

        let fieldName = '';

        // Get the field name from the name or id of the element
        if(event.target.name !== undefined && event.target.name !==''){
            fieldName = event.target.name;
        }
        else if(event.target.id !== undefined && event.target.id)
        {
            fieldName = event.target.id;
        }
        else
        {
            console.log('Missing required name or id for field');
        }

        this.isValueValidForField(event.target.value,fieldName);

        if(fieldName === 'auth_level')
        {
            // These other fields depend on auth_level, so if auth_level has changed
            // we should revalidate them
            this.isValueValidForField(this.state.user.group, 'group');
        }


    }

    // Clear all form fields by rolling back the state of the user and formErrors
    reset() {

        let untaintedUser = { ...this.state.untaintedUser };
        this.setState({user: untaintedUser});

        let formErrors = this.state.formErrors;

        Object.keys(formErrors).forEach(key => {
            formErrors[key].error = false;
            formErrors[key].helperText = '';
        });

        this.setState({formErrors: formErrors});

    }

    componentDidMount(){

        // If we were given a user id
        if(this.userId !== null)
        {
            // fetch the user from the backend
            this.fetchUser(this.userId);

            // fetch the classes associated with this user from the backend
            this.fetchClassesForUser(this.userId);
        }

        // Fetch a list of all classes from the backend
        this.fetchClassOptions();

        // If the logged-in user is a researcher
        if(this.props.auth.user.access === 3)
        {
            // Fetch a list of groups that users have previously been assigned to from the backend
            this.fetchUserGroups();

            // Fetch a list of all schools from the backend
            this.fetchSchools();
        }

    }

    componentWillUnmount() {
        clearTimeout(this.hideSuccessMessageTimeout);
        clearTimeout(this.passwordResetSuccessMessageTimeout);
    }

    // Fetch all the classes for this user, including the teachers for those classes
    fetchClassesForUser = (userId) => {
        axios.get('/api/users/getClassesForUser/' + userId).then(response => {
            this.setState({
                usersClasses: response.data
            });
        })
        .catch(e => {
            console.log(e);
        });
    }

    fetchUser = (userId) => {
        this.setState({...this.state, isFetching: true});

        axios.get("/api/users/getUser/" + userId).then(response => {
            this.setState({
                user: {
                    _id: response.data._id,
                    first_name: response.data.first_name,
                    last_name: response.data.last_name,
                    email: response.data.email,
                    auth_level: response.data.auth_level,
                    group: response.data.group,
                    status: response.data.status,
                    gender: response.data.gender,
                    consent: response.data.consent,
                    add_to_class_id: '',
                    generate_classes_academic_year: '',
                    generate_classes_implementation_term: '',
                    generate_classes_number_of_classes: '',
                    school: response.data.school
                },
                untaintedUser: {
                    _id: response.data._id,
                    first_name: response.data.first_name,
                    last_name: response.data.last_name,
                    email: response.data.email,
                    auth_level: response.data.auth_level,
                    group: response.data.group,
                    status: response.data.status,
                    gender: response.data.gender,
                    consent: response.data.consent,
                    add_to_class_id: '',
                    generate_classes_academic_year: '',
                    generate_classes_implementation_term: '',
                    generate_classes_number_of_classes: '',
                    school: response.data.school
                },
                isFetching: false
            });
        })
        .catch(e =>{
            console.log(e);
            this.setState({...this.state, isFetching: false});
        });

    }

    fetchClassOptions = () => {
        axios.get("/api/classes/getClassOptions").then(response => {
            this.setState({classOptions: response.data});
        })
        .catch(e => {
            console.log(e);
        })
    }

    fetchUserGroups = () => {
        axios.get("/api/users/getUserGroups").then(response => {
            this.setState({userGroups: response.data});
        })
        .catch(e => {
            console.log(e);
        });
    }

    fetchSchools = () => {
        axios.get("/api/schools/getSchoolNames").then(response => {
            this.setState({schools: response.data});
        })
        .catch(e =>{
            console.log(e);
        });
    }

    applyFormErrors = (errorsToApply) => {

        let formErrors = this.state.formErrors;

        if(errorsToApply) {
            Object.keys(errorsToApply).forEach(key => {
                if(formErrors[key] !== undefined)
                {
                    formErrors[key].error = true;
                    formErrors[key].helperText = errorsToApply[key];
                }
                else
                {
                    console.log('Missing expected form field: ' + key + ' for error: ' + errorsToApply[key]);
                }
            });
        }

        this.setState({formErrors: formErrors});
    }

    passwordReset = () => {

        if(!window.confirm('Are you sure you want to reset this user\'s password?'))
        {
            return;
        }

        axios.post('/api/users/reset-password', {
            _id: this.state.user._id
        }).then((response) => {

            if(!response.data.success)
            {
                this.applyFormErrors(response.data.errors);
            }
            else
            {
                // Show a success message that will disappear after 10 second
                this.setState({passwordResetSuccessMessage: 'New password sent to user\'s email address'});
                this.hidePasswordResetSuccessMessageTimeout = setTimeout(() => this.setState({passwordResetSuccessMessage: ''}), 10000);
            }

        }).catch((error) => {
            console.log(error);
        });
    }

    // Handler for "Remove Student From Class" button clicks
    removeFromClass = (classId) => {
        axios.post('/api/users/removeStudentFromClass', {
            studentId: this.state.user._id,
            classId: classId
        }).then((response) => {

            if(!response.data.success)
            {
                // The user won't be able to resolve any error, so we'll just log to the console for debugging
                // If they click the Remove button again perhaps the server issue will be resolved
                console.log('Error response from backend: ' + response.data.errors.deleteButton);
            }
            else
            {
                // Refresh the list of classes associated with this user
                this.setState({ usersClasses: response.data.usersClasses});
            }

        }).catch((error) => {
            console.log(error);
        });

    }

    // Handler for "Delete Class" button clicks
    deleteClass = (classId) => {

        if(!window.confirm('Are you sure you want to remove all students from this class and delete it?'))
        {
            return;
        }

        axios.post('/api/users/deleteClassForTeacher', {
            teacherId: this.state.user._id,
            classId: classId
        }).then((response) => {

            if(!response.data.success)
            {
                // The user won't be able to resolve any error, so we'll just log to the console for debugging
                // If they click the Remove button again perhaps the server issue will be resolved
                console.log('Error response from backend: ' + response.data.errors.deleteButton);
            }
            else
            {
                // Refresh the list of classes associated with this user
                this.setState({ usersClasses: response.data.usersClasses});
            }

        }).catch((error) => {
            console.log(error);
        });

    }

    save = () => {

        // If the form isn't valid
        if(!this.validateAllFormFields())
        {
            return;
        }

        // If we're editing an existing user
        if(this.state.user._id)
        {
            // auth_level can't be updated
            // password update happens via a reset email
            axios.post('/api/users/update', {
                _id: this.state.user._id,
                first_name: this.state.user.first_name,
                last_name: this.state.user.last_name,
                email: this.state.user.email,
                gender: this.state.user.gender,
                group: this.state.user.group,
                academic_year: this.state.user.academic_year,
                implementation_term: this.state.user.implementation_term,
                status: this.state.user.status,
                consent: this.state.user.consent,
                add_to_class_id: this.state.user.add_to_class_id,
                generate_classes_academic_year: this.state.user.generate_classes_academic_year,
                generate_classes_implementation_term: this.state.user.generate_classes_implementation_term,
                generate_classes_number_of_classes: this.state.user.generate_classes_number_of_classes,
                school: this.state.user.school
            }).then((response) => {
                // console.log(response);

                if(!response.data.success)
                {
                    this.applyFormErrors(response.data.errors);
                }
                else
                {
                    // Show a success message that will disappear after 10 second
                    this.setState({successMessage: 'User successfully updated'});
                    this.hideSuccessMessageTimeout = setTimeout(() => this.setState({successMessage: ''}), 10000);

                    // Refresh the list of classes associated with this user
                    this.setState({ usersClasses: response.data.usersClasses});

                    // Clear the fields for creating new classes, so if the user hits Save again they don't accidentally create more
                    let userState = this.state.user;
                    userState.generate_classes_academic_year = '';
                    userState.generate_classes_implementation_term = '';
                    userState.generate_classes_number_of_classes = '';
                    this.setState({ user: userState });

                    let userGroups = this.state.userGroups;

                    // If the saved Group value wasn't already in our list of groups
                    if(userGroups.indexOf(this.state.user.group) < 0 && this.state.user.group !== '')
                    {
                        // add the group to our list
                        userGroups.push(this.state.user.group);
                    }

                }

            }).catch((error) => {
                console.log(error);
            });

        }
        else // We're creating/registering a new user
        {
            axios.post('/api/users/register', {
                _id: this.state.user._id,
                auth_level: this.state.user.auth_level,
                first_name: this.state.user.first_name,
                last_name: this.state.user.last_name,
                email: this.state.user.email,
                gender: this.state.user.gender,
                group: this.state.user.group,
                status: this.state.user.status,
                consent: this.state.user.consent,
                add_to_class_id: this.state.user.add_to_class_id,
                generate_classes_academic_year: this.state.user.generate_classes_academic_year,
                generate_classes_implementation_term: this.state.user.generate_classes_implementation_term,
                generate_classes_number_of_classes: this.state.user.generate_classes_number_of_classes,
                school: this.state.user.school
            }).then((response) => {

                if(!response.data.success)
                {
                    this.applyFormErrors(response.data.errors);
                }
                else
                {
                    // Show a success message that disappears after 10 second
                    this.setState({successMessage: 'New user created. Login credentials have been sent to their email address'});
                    this.hideSuccessMessageTimeout = setTimeout(() => this.setState({successMessage: ''}), 10000);

                    // Update the list of options for Class dropdown
                    this.setState({classOptions: response.data.classOptions});

                    // Clear the inputs and error messages, so the user can create an additional user
                    this.reset();
                }

            }).catch((error) => {
                console.log(error);
            });

        }



    }

   render(){
        const { classes } = this.props;
        const loggedInUserIsTeacher = this.props.auth.user.access === 2;
        const isExistingUser = this.state.user._id === "" ? false : true;
        return (
            <div className={classes.pageMargin}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                        { loggedInUserIsTeacher && isExistingUser
                           ?
                           <Typography variant="subtitle1" component="h2" color="primary">Edit Student</Typography>
                           :
                           loggedInUserIsTeacher && !isExistingUser
                           ?
                           <Typography variant="subtitle1" component="h2" color="primary">Create Student</Typography>
                           :
                           !loggedInUserIsTeacher && isExistingUser
                           ?
                           <Typography variant="subtitle1" component="h2" color="primary">Edit User</Typography>
                           :
                           <Typography variant="subtitle1" component="h2" color="primary">Create User</Typography>
                        }
                </Grid>
                <Grid item xs={12}>
                        <FormControl fullWidth={true} className={classes.formControl} variant="outlined" required
                        error={this.state.formErrors.auth_level.error}
                        margin="normal"
                        >
                            <InputLabel id="user-type-label">User Type</InputLabel>
                            <Select
                                labelId="user-type-label"
                                label="User Type"
                                id="auth_level"
                                name="auth_level"
                                value={ this.state.user.auth_level ?? ""}
                                onChange={ e => { this.handleFieldChange(e); this.blurOrChangeValidate(e);} }
                                disabled={isExistingUser || loggedInUserIsTeacher}
                            >
                            {/* onBlur() doesn't exist on mui Select, so only doing validation on change */}
                            {
                                Object.keys(UserTypeMap).map((userTypeKey, i) => (
                                    <MenuItem key={"userTypeRow" + i} value={userTypeKey}>{UserTypeMap[userTypeKey]}</MenuItem>
                                ))
                            }

                            </Select>
                            <FormHelperText>{this.state.formErrors.auth_level.helperText}</FormHelperText>
                        </FormControl>

                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>

                    <TextField required className={classes.inputName} value={this.state.user.first_name} id="first_name"
                        label="First Name" variant="outlined" size="medium" onChange={this.handleFieldChange}
                        error={this.state.formErrors.first_name.error} helperText={this.state.formErrors.first_name.helperText}
                        onBlur={this.blurOrChangeValidate}
                        inputProps={{ maxLength: this.maximumTextFieldLength }}
                        />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>


                    <TextField required className={classes.inputName} value={this.state.user.last_name} id="last_name"
                        label="Last Name" variant="outlined" size="medium" onChange={this.handleFieldChange}
                        error={this.state.formErrors.last_name.error} helperText={this.state.formErrors.last_name.helperText}
                        onBlur={this.blurOrChangeValidate}
                        inputProps={{ maxLength: this.maximumTextFieldLength }}
                        />

                </Grid>
                <Grid item xs={12} md={4} lg={6}>
                <FormControl fullWidth={true} className={classes.formControl} variant="outlined"
                            error={this.state.formErrors.gender.error}
                            margin="normal"
                            >
                            <InputLabel id="user-gender-label">Gender</InputLabel>
                            <Select
                                labelId="user-gender-label"
                                id="gender"
                                name="gender"
                                value={this.state.user.gender ?? ""}
                                onChange={ e => { this.handleFieldChange(e); this.blurOrChangeValidate(e);} }
                                label="Gender"
                            >
                            {
                                this.getGenders().map((gender, i) =>
                                <MenuItem key={"genderOption" + i} value={gender}>{gender}</MenuItem>
                                )
                            }
                            </Select>
                            <FormHelperText>{this.state.formErrors.gender.helperText}</FormHelperText>
                        </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>

                        <TextField required className={classes.inputEmail} value={this.state.user.email} id="email"
                        label="Email Address" variant="outlined" size="medium" onChange={this.handleFieldChange}
                        error={this.state.formErrors.email.error} helperText={this.state.formErrors.email.helperText}
                        onBlur={this.blurOrChangeValidate}
                        inputProps={{ maxLength: this.maximumTextFieldLength }}
                        />


                </Grid>
                {
                    // Show School field if the logged-in user is a researcher AND (we're creating a student or teacher)
                    (!loggedInUserIsTeacher)
                    &&
                    (
                        (this.state.user.auth_level === 1 || this.state.user.auth_level === "1") ||  // student
                        (this.state.user.auth_level === 2 || this.state.user.auth_level === "2")   // teacher
                    )
                ?
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormControl fullWidth={true} className={classes.schoolSelector} variant="outlined"
                            error={this.state.formErrors.school.error}
                            disabled={loggedInUserIsTeacher}
                            margin="normal"
                            >
                            <InputLabel id="user-school-label">School</InputLabel>
                            <Select
                                labelId="user-school-label"
                                id="school"
                                name="school"
                                value={this.state.user.school ?? ""}
                                onChange={ e => { this.handleFieldChange(e); this.blurOrChangeValidate(e);} }
                                label="School"
                            >
                            {
                                this.state.schools.map((school, i) =>
                                <MenuItem key={"schoolOption" + i} value={school}>{school}</MenuItem>
                                )
                            }
                            </Select>
                            <FormHelperText>{this.state.formErrors.school.helperText}</FormHelperText>
                        </FormControl>
                    </Grid>
                :
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        &nbsp;
                    </Grid>
                }

                <Grid item xs={12} md={4} lg={6}>
                     { isExistingUser ? <FormControl error={this.state.formErrors.password_reset_button.error}>
                        <FormHelperText>{this.state.formErrors.password_reset_button.helperText}</FormHelperText>
                        <FormHelperText>{this.state.passwordResetSuccessMessage}</FormHelperText>
                        <Button className={classes.button} variant="contained" color="primary" onClick={() => this.passwordReset()}>Reset Password</Button>
                    </FormControl> : null }
                </Grid>

                {
                     // Show the Group field if (this is an existing user or the logged-in user is a researcher) AND (we're creating a student or teacher)
                     (isExistingUser || !loggedInUserIsTeacher)
                     &&
                     (
                         (this.state.user.auth_level === 1 || this.state.user.auth_level === "1") ||  // student
                         (this.state.user.auth_level === 2 || this.state.user.auth_level === "2")   // teacher
                     )
                     &&
                     <Grid item xs={12} sm={6} md={4} lg={3}>

                        <FormControl fullWidth={true} className={classes.group} margin="normal">
                            { /* <InputLabel id="user-group-label">Group</InputLabel> */}
                            <Autocomplete
                                                disabled={loggedInUserIsTeacher}
                                                className={classes.autoComplete}
                                                closeIcon={null} // don't show the X clear icon/button because its click event doesn't provide onChange with a way to get the value, and I couldn't find a good workaround
                                                autoSelect={true}
                                                freeSolo={true}
                                                onChange={ e => { this.handleFieldChange(e); this.blurOrChangeValidate(e);} }
                                                options={this.getGroupAutocompleteOptions()} // options format is ["1st group name","2nd group name",...]
                                                id="group"
                                                value={this.state.user.group || null}
                                                renderInput={(params) => <TextField {...params} label="Group" variant="outlined"
                                                    error={this.state.formErrors.group.error}
                                                    helperText={this.state.formErrors.group.helperText}
                                                    inputProps={{ ...params.inputProps, maxLength: this.maximumTextFieldLength }}
                                                    />}
                                            />
                        </FormControl>
                    </Grid>
                }


                <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormControl fullWidth={true} className={classes.statusSelector} variant="outlined"
                            error={this.state.formErrors.status.error} required
                            margin="normal">
                            <InputLabel id="user-status-label">Status</InputLabel>
                            <Select
                                labelId="user-status-label"
                                id="status"
                                name="status"
                                value={this.state.user.status ? 'Active' : 'Inactive'}
                                onChange={ e => { this.handleFieldChange(e); this.blurOrChangeValidate(e);} }
                                label="Status"
                            >

                                    <MenuItem key="status0" value="Active">Active</MenuItem>
                                    <MenuItem key="status1" value="Inactive">Inactive</MenuItem>
                            </Select>
                            <FormHelperText>{this.state.formErrors.status.helperText}</FormHelperText>
                        </FormControl>

                </Grid>
                <Grid item xs={12}>

                        <FormControl error={this.state.formErrors.consent.error}>
                        <FormGroup>
                            {/* By default Switch uses the secondary color of the mui theme. Manually overriding it to primary here */}
                            <FormControlLabel control={<Switch id="consent"
                                color="primary"
                                onChange={ e => { this.handleFieldChange(e); this.blurOrChangeValidate(e);} }
                                checked={this.state.user.consent ? true : false}
                                />} label="Consent Given?" />
                        </FormGroup>
                        <FormHelperText>{this.state.formErrors.consent.helperText}</FormHelperText>
                        </FormControl>

                </Grid>
                { (this.state.user.auth_level === 1 || this.state.user.auth_level === "1")
                ?
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" component="h3" color="primary">Add To Class</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>

                            <FormControl fullWidth={true} className={classes.classSelector} variant="outlined"
                                error={this.state.formErrors.add_to_class_id.error}
                                margin="normal">
                                <InputLabel id="add-to-class-label">Add to Class</InputLabel>
                                <Select
                                    labelId="add-to-class-label"
                                    id="add_to_class_id"
                                    name="add_to_class_id"
                                    label="Add to Class"
                                    value={this.state.user.add_to_class_id ?? ""}
                                    onChange={ e => { this.handleFieldChange(e); this.blurOrChangeValidate(e);} }
                                >
                                    <MenuItem key={"classOption0"} value={""}>None</MenuItem>
                                {
                                    this.state.classOptions.map((classObj, i) =>
                                    <MenuItem key={"classOption" + i} value={classObj._id}>{classObj.academicYear + ' - ' + classObj.implementationTerm +
                                        (classObj.teacherName !== undefined ? ' - ' + classObj.teacherName : '') + ' #' + classObj.classNumber}</MenuItem>
                                    )
                                }
                                </Select>
                                <FormHelperText>{this.state.formErrors.add_to_class_id.helperText}</FormHelperText>
                            </FormControl>

                    </Grid>
                </React.Fragment>
                : (this.state.user.auth_level === 2 || this.state.user.auth_level === "2")
                ?
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" component="h3" color="primary">Create Classes</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormControl fullWidth={true} className={classes.academicYear} variant="outlined"
                            error={this.state.formErrors.generate_classes_academic_year.error}
                            disabled={loggedInUserIsTeacher}
                            margin="normal">
                            <InputLabel id="user-academic-year-label">Academic Year</InputLabel>
                            <Select
                                labelId="user-academic-year-label"
                                id="generate_classes_academic_year"
                                name="generate_classes_academic_year"
                                value={this.state.user.generate_classes_academic_year ?? ""}
                                onChange={ e => { this.handleFieldChange(e); this.blurOrChangeValidate(e);} }
                                label="Academic Year"
                            >
                                <MenuItem key={"classOption0"} value={""}>None</MenuItem>
                            {
                                this.getAcademicYears().map((academicYear, i) => (
                                    <MenuItem key={"academicYearRow" + i} value={academicYear.human_readable}>{academicYear.human_readable}</MenuItem>
                                ))
                            }

                            </Select>
                            <FormHelperText>{this.state.formErrors.generate_classes_academic_year.helperText}</FormHelperText>
                        </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>

                        <FormControl fullWidth={true} className={classes.implementationTerm} variant="outlined"
                            error={this.state.formErrors.generate_classes_implementation_term.error}
                            disabled={loggedInUserIsTeacher}
                            margin="normal">
                            <InputLabel id="user-implementation-term-label">Implementation Term</InputLabel>
                            <Select
                                labelId="user-implementation-term-label"
                                id="generate_classes_implementation_term"
                                name="generate_classes_implementation_term"
                                value={this.state.user.generate_classes_implementation_term ?? ""}
                                onChange={ e => { this.handleFieldChange(e); this.blurOrChangeValidate(e);} }
                                label="Implementation Term"
                            >
                                <MenuItem key={"classOption0"} value={""}>None</MenuItem>
                            {
                                this.getTerms().map((term, i) => (
                                    <MenuItem key={"ImplementationTermRow" + i} value={term}>{term}</MenuItem>
                                ))
                            }

                            </Select>
                            <FormHelperText>{this.state.formErrors.generate_classes_implementation_term.helperText}</FormHelperText>
                        </FormControl>

                    </Grid>
                    <Grid item xs={12} md={4} lg={6}>
                        <TextField className={classes.numberOfClasses} value={this.state.user.generate_classes_number_of_classes} id="generate_classes_number_of_classes"
                            label="Number of Classes" variant="outlined" size="medium" onChange={this.handleFieldChange}
                            error={this.state.formErrors.generate_classes_number_of_classes.error} helperText={this.state.formErrors.generate_classes_number_of_classes.helperText}
                            onBlur={this.blurOrChangeValidate}
                            inputProps={{ maxLength: this.maximumTextFieldLength }}
                            />
                    </Grid>
                </React.Fragment>
                :
                <Grid item xs={9}></Grid>
                }
                <Grid item xs={12}>

                        <FormControl error={this.state.formErrors.save_button.error}>
                            <FormHelperText>{this.state.formErrors.save_button.helperText}</FormHelperText>
                            <FormHelperText>{this.state.successMessage}</FormHelperText>
                            <Button className={classes.button} variant="contained" color="primary" onClick={() => this.save()}>Save</Button>
                        </FormControl>
                        {/* <Button variant="contained" color="secondary" onClick={() => this.reset()}>Reset</Button> */}

                </Grid>
            </Grid>
            {isExistingUser && this.state.usersClasses.length > 0
            ?
            <React.Fragment>
                <Grid item xs={12}>
                    <br />
                    <br />
                    <hr />
                    <Typography variant="subtitle2" component="h3" color="primary">Current Class Assignments</Typography>
                </Grid>
                {this.state.usersClasses
                ?
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Academic Year</TableCell>
                                <TableCell>Term</TableCell>
                                <TableCell>Teacher</TableCell>
                                <TableCell>Class Number</TableCell>
                                <TableCell># of Students Enrolled</TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.usersClasses.map((classModel, i) => (
                                <TableRow key={"classRow" + i}>
                                    <TableCell>{classModel.academicYear}</TableCell>
                                    <TableCell>{classModel.implementationTerm}</TableCell>
                                    <TableCell>{classModel.teacher ? classModel.teacher.last_name + ', ' + classModel.teacher.first_name : null}</TableCell>
                                    <TableCell>Class {classModel.classNumber}</TableCell>
                                    <TableCell>{classModel.classSize}</TableCell>
                                    { parseInt(this.state.user.auth_level) === 1
                                    ?
                                        (this.state.usersClasses.length > 1)
                                        ?
                                        <TableCell><Button className={classes.button} variant="contained" color="primary" onClick={() => this.removeFromClass(classModel._id)}>Remove From Class</Button></TableCell>
                                        :
                                        null
                                    :
                                    <TableCell><Button className={classes.button} variant="contained" color="primary" onClick={() => this.deleteClass(classModel._id)}>Delete Class</Button></TableCell>
                                    }

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <div>&nbsp;</div>
                }
            </React.Fragment>
            : <div>&nbsp;</div>
            }
            </div>
        )
   }
}

useGridRegisterStrategyProcessor.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
   ) (withStyles(styles) (UserEditor));

