import React, { Component, Fragment } from "react";
//import './App.css';
// import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";

//Redux components
import { connect } from "react-redux";
import store from "./store";

//Discussion context
import { NoteContextProvider } from "./reducers/noteContext";
import { FullClassContextProvider } from "./reducers/fullClassContext";

//Auth token
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser, setTheme } from "./actions/authActions";
import { Redirect } from "react-router-dom";

import Navbar from "./components/layout/Navbar";
import Landing from "./components/layout/Landing";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import ResearcherRoute from "./components/private-route/ResearcherRoute";
import Dashboard from "./components/dashboard/Dashboard";
import Access from "./components/userlevels/Access";
import CreateClass from "./components/userlevels/CreateClass";

//Notebook Stuff
import Notebook from "./components/notebook/Notebook";
//line below is where overall theme is from (previously the green style)
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { adminTheme, adminMuiTheme } from "./ildlTheme";
import { ThemeProvider as MuiThemeProvider, createTheme as createMuiTheme } from "@mui/material/styles";
//Admin Stuff
import UserList from "./components/admin/UserList";
import UserEditor from "./components/admin/UserEditor";
import ClassroomCreator from "./components/admin/ClassroomCreator";
import AssignLesson from "./components/admin/AssignLesson";
import LabUnlock from "./components/admin/notebook_control/LabUnlock";
import UploadUsers from "./components/admin/UploadUsers";
import DataDownload from "./components/admin/DataDownload";
import SchoolList from "./components/admin/SchoolList";
import SchoolEditor from "./components/admin/SchoolEditor";
//Unit/Lab/Page Creation
import UnitCreator from "./components/admin/unit_creation/UnitCreator";
import ContentCreator from "./components/admin/unit_creation/ContentCreator";
//PyrEval stuff
import PyrEvalOverwrite from "./components/admin/notebook_control/PyrEvalOverwrite";
//Guest stuff
import GuestCreator from "./components/admin/GuestCreator";
//Test
import Test from "./components/test/Test";

//Teacher Stuff
//import OrchDashboard from "./components/teacher/orchestration/orchDashboard";
import AnalyticDashboard from "./components/dashboard/teacher/AnalyticDashboard";
//AnalyticDashboard for admin/researchers to use
import TeacherAnalyticDashboard from "./components/admin/teacher_dashboard_view/TeacherAnalyticDashboard";
import SimulationContent from "./components/admin/unit_creation/SimulationContent";

//Vidyamap Stuff
import Vidyamap from "./components/notebook/Vidyamap";

//Discussion Stuff
import SeatingChart from "./components/discussion/SeatingChart";
import RoomView from "./components/discussion/RoomView";
import Authoring from "./components/discussion/Authoring";
import Discussion from "./components/discussion/Discussion";
import FullDiscussion from "./components/discussion/FullDiscussion";
import FullDiscussionNote from "./components/discussion/FullDiscussionNote";
import StudentResponseForm from './components/discussion/StudentResponseForm';
import WaitingRoom from "./components/discussion/Waiting";

//Static Pages
import Research from "./components/static/Research";
//PyrEval Stuff
import PyrEvalStatus from "./components/admin/pyreval/PyrEvalStatus";

/* Eventually this should be changed to a cookie, rather than local storage */
let decoded = null;
if (localStorage.jwtToken) {
  //Set auth token header
  const token = localStorage.jwtToken;
  setAuthToken(token);
  //Decode token to get user info
  decoded = jwt_decode(token);
  //Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  //Set theme for user
  const userTheme = decoded?.access !== 1 ? adminTheme : createTheme({});
  const userMuiTheme = decoded?.access !== 1 ? adminMuiTheme : createMuiTheme({});
  store.dispatch(setTheme({material: userTheme, mui: userMuiTheme}));

  //Checking for expired token
  const currentTime = Date.now() / 1000; // Time in milliseconds
  if (decoded.exp < currentTime) {
    //Logout user
    store.dispatch(logoutUser());

    //Redirect to login screen
    window.location.href = "./login";
  }
}

// const outerTheme = createMuiTheme({
//   palette: {
//     primary: {
//       main: orange[500],
//     },
//   },
// });

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: props.auth.theme.material,
      muiTheme: props.auth.theme.mui
    };
  }

  // React lifecycle hook called before render()
  // Returns updated state themes if props auth theme value has changed (e.g., a different user type logs in)
  static getDerivedStateFromProps(props, state) {
    return state.theme !== props.auth.theme.material ? {theme: {...props.auth.theme.material}, muiTheme: {...props.auth.theme.mui}} : {...state};
  }

  render() {
    return (
      //Again, this is where overall theme would live
      <ThemeProvider theme={this.state.theme}>
        <MuiThemeProvider theme={this.state.muiTheme}>
        <NoteContextProvider>
        <FullClassContextProvider>
          <Router>
            <Navbar />
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/login" component={Login} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/access" component={Access} />
              <PrivateRoute exact path="/test/" component={Test} />
              <PrivateRoute
                exact
                path="/dashboard/classcreation"
                component={CreateClass}
              />
              <PrivateRoute
                exact
                path="/notebook/:activeLab?/:activeTab?/:activeStep?"
                component={Notebook}
                componentProps={{ baseUrl: "/notebook" }}
              />
              <PrivateRoute path="/users" component={UserList} />
              <PrivateRoute path="/user/:userId?" component={UserEditor} />
              <PrivateRoute path="/classcreate" component={ClassroomCreator} />
              <PrivateRoute path="/assignlesson" component={AssignLesson} />
              <PrivateRoute path="/labunlocker" component={LabUnlock} />
              <PrivateRoute exact path="/vidyamap" component={Vidyamap} />
              <PrivateRoute
                path="/teacher/analytics"
                component={AnalyticDashboard}
              />
              <PrivateRoute path="/uploadusers" component={UploadUsers} />
              <PrivateRoute path="/datadownload" component={DataDownload} />
              <ResearcherRoute path="/schools" component={SchoolList} />
              <ResearcherRoute
                path="/school/:schoolId?"
                component={SchoolEditor}
              />
              <PrivateRoute path="/guestcreate" component={GuestCreator} />
              <PrivateRoute path="/pyreval_status" component={PyrEvalStatus} />
              <PrivateRoute path="/analyticdashview" component={TeacherAnalyticDashboard} />
              <PrivateRoute exact path="/createunit" component={UnitCreator} />
              <PrivateRoute
                path="/createcontent/:lessonName"
                component={ContentCreator}
              />
              <PrivateRoute path="/authoring" component={Authoring} />
              <PrivateRoute exact path="/notebook/studentresponse" component={StudentResponseForm} />
              <PrivateRoute path="/roomview" component={RoomView} />
              <PrivateRoute path="/seatingchart" component={SeatingChart} />
              <PrivateRoute
                path="/fulldiscussion/note/:unit/:id"
                children={(props) => <FullDiscussionNote {...props} />}
              />
              <PrivateRoute
                path='/fulldiscussion/:id'
                children={(props) => <FullDiscussion {...props} />}
              />
              <PrivateRoute
                path='/discussion/:id'
                children={(props) => <Discussion {...props} />}
              />
              <PrivateRoute path="/discussion" component={WaitingRoom} />
              <PrivateRoute exact path="/simcontent/:unit/:lab" component={SimulationContent} />
            </Switch>
          </Router>
          </FullClassContextProvider>
          </NoteContextProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps) (App);
