import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Modal,
  Grid,
  ListItem,
  Box
} from '@mui/material';
import StudentResponse from './StudentResponse';
import store from '../../store';
import socket from '../../utils/socket';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import StudentResponseForm from './StudentResponseForm';
import StudentCombineForm from './StudentCombineForm';
import { NoteContext } from '../../reducers/noteContext';
import { logoutUser } from "../../actions/authActions";
import NoteManager from '../notebook/elements/Notes/NoteManager';
import { pushMapping } from './constants/PushMapping';

import { Button } from './common/Button';
import { DropZone } from './common/DropZone';
import { SideBar } from './common/SideBar';

import corn from '../../assets/img/corn.png';
import greenbean from '../../assets/img/greenbean.png';
import kale from '../../assets/img/kale.png';
import lettuce from '../../assets/img/lettuce.png';
import potato from '../../assets/img/potato.png';
import radish from '../../assets/img/radish.png';
import soybean from '../../assets/img/soybean.png';
import squash from '../../assets/img/squash.png';
import sunflower from '../../assets/img/sunflower.png';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    height: 'auto',
    padding: '45px',
    backgroundColor: '#ffffff',
    border: '27px solid #e3a920',
    borderRadius: '94px',
    boxShadow: '0px 3.23px 3.23px #00000040, 0px 3.23px 3.23px #00000040'
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 0
  }
}));

const StyledIdeaWallBackground = styled.div`
  height: 100%;
  position: relative;
  background-color: #f0f0f0;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  & img {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  & .blob {
    position: absolute;
    top: 0;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  & .blob-right {
    right: 0;
    position: absolute;
    top: 0;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  & .combine-zone {
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    top: 135px;
  }

  & .title {
    color: #ffffff;
    font-family: "K2D-Bold", Helvetica;
    font-size: 80px;
    font-weight: 700;
    left: 500px;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.4;
    position: absolute;
    text-align: center;
    top: 15px;
    width: 450px;
  }

  & .label {
    height: 43px;
    width: 96px;
    color: #8d8d8d;
    font-family: "Kaisei Tokumin-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    position: fixed;
    text-align: center;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 155px;
    transform: rotate(0.82deg);
  }

  & .group-discussion {
    color: #252525;
    font-family: "Jua", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: fixed;
    text-align: center;
    right: 0;
    top: 15px;
    margin: 0 auto;
    width: 716px;
    z-index: 1;
  }

  & .ellipse {
    background-color: ${props => props.color ? props.color : "#f1ffdd"};
    border-radius: 84px/79.5px;
    height: 159px;
    right: 40px;
    position: absolute;
    top: -60px;
    width: 168px;
    z-index: 1;
  }

  & .team {
    position: absolute;
    width: auto;
    height: 89px;
    right: 80px;
    top: 0;
    z-index: 1;
  }

  & .team-text {
    color: #969696;
    font-family: "Inter-Black", Helvetica;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    width: 110px;
    right: 70px;
    top: 100px;
    position: absolute;
    z-index: 1;
  }
`;

const colorList = ['#EF9971', '#EFDA71', '#71EFB6', '#71C7EF', '#FFB8D2'];

const groupImageMapping = {
  'Soybean': {
    'image': soybean,
    'color': '#E2F4C6'
  },
  'Sunflower': {
    'image': sunflower,
    'color': '#EB8D48'
  },
  'Squash': {
    'image': squash,
    'color': '#FAB8B8'
  },
  'Corn': {
    'image': corn,
    'color': '#FAB8B8'
  },
  'Kale': {
    'image': kale,
    'color': '#A0B5FF'
  },
  'Potato': {
    'image': potato,
    'color': '#B88787'
  },
  'Green Bean': {
    'image': greenbean,
    'color': '#AEF4D3'
  },
  'Lettuce': {
    'image': lettuce,
    'color': '#E4E06C'
  },
  'Radish': {
    'image': radish,
    'color': '#E2CBFF'
  }
}

export default function Discussion({ match }) {
  const discussionId = match.params.id;
  const [discussion, setDiscussion] = useState({});
  const [navigation, setNavigation] = useState('');
  const [loadingNavigation, setLoadingNavigation] = useState(false);
  const [loadingDiscussion, setLoadingDiscussion] = useState(true);
  const [loadingNotes, setLoadingNotes] = useState(true);
  const [, dispatch] = useContext(NoteContext);
  const [room, setRoom] = useState('');
  const user = store.getState().auth.user;
  const history = useHistory();

  useEffect(() => {
    // 403 and refuse socket connection
    // requirement for notebook notes
    if (user.id === undefined) {
      history.push(`/login`);
    } else {
      socket.connect();
    }

    if (user.access === 1) {
      try {
        axios.post("/api/navigation/getnavigation",
          { studentId: user.id }
        ).then((res) => {
          setNavigation(res.data[0]);
          setLoadingNavigation(false);
        });
      } catch(err) {
        dispatch({
          type: 'SET_ERROR',
          payload: err
        });
      }
    } else {
      setLoadingNavigation(false);
    }

    try {
      axios.get('/api/discussion',
        { params: { data: discussionId } }
      ).then(res => {
        setDiscussion(res.data);
        setRoom(`/${res.data.groupNumber}`);
        setLoadingDiscussion(false);
      });
    } catch(err) {
      dispatch({
        type: 'SET_ERROR',
        payload: err.response.data
      });
    }

    try {
      axios.get('/api/notePositions/lastPositions',
        { params: { discussionId } }
      ).then(res => {
        setLoadingNotes(false);
        dispatch({
          type: 'SET_NOTES',
          payload: res.data
        });
      });
    } catch(err) {
      dispatch({
        type: 'SET_ERROR',
        payload: err.response.data
      });
    }
    // eslint-disable-next-line
  }, []);
  if (loadingDiscussion || loadingNotes || loadingNavigation) return (<div><p>Please wait...</p></div>)
  return (
    <>
      <DiscussionComponent
        discussionId={discussionId}
        discussion={discussion}
        navigation={navigation}
        room={room}
        user={user}
      />
    </>
  )
}

function DiscussionComponent({ discussionId, discussion, navigation, room, user }) {
  const classes = useStyles();
  const history = useHistory();
  const [isApproved, setIsApproved] = useState(false);
  const [openRequestApproval, setOpenRequestApproval] = useState(false);
  const [openResponse, setOpenResponse] = useState(false);
  const [openCombine, setOpenCombine] = useState(false);
  const [staged, setStaged] = useState('');
  const [ingredientIds, setIngredientIds] = useState([]);
  const [noteText, setNoteText] = useState('');
  const [noteOriginId, setNoteOriginId] = useState(null);
  const [finalizedNotes, setFinalizedNotes] = useState([]);
  const [{ notes, roomUsers, doneUsers }, dispatch] = useContext(NoteContext);

  const [newTag, setNewTag] = useState('');
  const [tags, setTags] = useState([]);

  const userId = user.id;
  const auth_level = user.access;
  const fullName = `${user.first_name} ${user.last_name}`;
  const lessonId = navigation.Lesson_Id;
  const rawRoom = room.split('/')[1];

  const [error, setError] = useState(null);
  // NoteManager state and functions
  const [showNotes, setShowNotes] = useState(false);
  const closeNotes = () => setShowNotes(false);

  const handleTeacherNotification = message => {
    let yups = []
    let nopes = []
    let roomUserIds = []

    // allows only a single user to save a teacher notification
    if (finalizedNotes && finalizedNotes.length) {
      for (let user of roomUsers) {
        roomUserIds.push(user.userId);
      }
      for (let note of notes) {
        if (note.vote === 'yup') {
          yups.push(note.noteId);
        } else if (note.vote === 'nope') {
          nopes.push(note.noteId);
        }
      }
      try {
        axios.post("/api/teacherNotification/save", {
          discussionId: discussionId,
          userIds: roomUserIds,
          status: message,
          groupNumber: room,
          yupNoteIds: yups,
          nopeNoteIds: nopes
        });
      } catch (err) {
        setError(err.response.data)
      }
    }
  }

  const handleCombineOpen = () => {
    setIngredientIds([]);
    setStaged('');
    const data = notes.filter(function(object){
        return object.vote === 'combine';
    }).map(function({noteId, content}){
        setIngredientIds((oldArray) => oldArray.concat(noteId))
        return content;
    });
    setStaged(data.join('; '));
    setOpenCombine(true);
  }

  const handleResponseOpen = ({body, originId}) => {
    setOpenResponse(true);
    // Populating text from notemanager reponseModal
    if (typeof body === 'string' || body instanceof String) setNoteText(body);
    if (typeof originId === 'string' || originId instanceof String) setNoteOriginId(originId);
  };

  const handleResponseClose = () => {
    setOpenResponse(false)
    // cleanup
    setNoteText('');
    setNoteOriginId(null);
  };

  const handleNoteTextChange = (e) => {
    setNoteText(e.target.value);
  }

  const handleCombineClose = () => setOpenCombine(false);

  const handleSubmit = async e => {
    e.preventDefault();
    // locking in discussion users when they tick in
    try {
      await axios.put('/api/discussion/userIds/update', {
        discussionId,
        userId: userId
      });
    } catch(err) {
      setError(err.response.data)
    }
    socket.emit(`${room}/done`, userId);
  }

  const handleRenunciateApproval = () => {
    // teacher notification update: student rework
    handleTeacherNotification('student rework');
    dispatch({
      type: 'REMOVE_DONE_USER',
      payload: userId
    });
    setOpenRequestApproval(false);
    setFinalizedNotes([]);
    socket.emit(`${room}/renunciate`, room);
    socket.emit(`${room}/groupRenunciate`, room);
  }

  const handleFinish = e => {
    e.preventDefault();
    socket.emit(`${room}/notifyTeacher`, room);
    socket.emit(`${room}/groupPropogate`, room);
    // only one user is setting finalized notes
    // this is intentional! preventing multiple users
    // from pushing the same set of notes to full discussion
    setFinalizedNotes(notes.filter(f => f.vote === 'yup'));
    setOpenRequestApproval(true);
  }

  const alertUser = e => {
    e.preventDefault()
    e.returnValue = ''
  }

  const handleAway = async () => {
    socket.emit(`${room}/leave`, userId);
  }

  const handleLogoutUser = e => {
    e.preventDefault();
    store.dispatch(logoutUser());
    // glitchy, had to add this.
    history.push('/login');
  }

  const handleNewTag = (e) => {
    e.preventDefault();
    if (newTag) {
      try {
        axios.put('/api/unit/tag', {
          order: discussion.unit, newTag
        }).then((res) => {
          // pushing tag through socket.on for ALL users
          // TODO::update state locally then push to other users
          socket.emit(`${room}/tags/update`, newTag);
          socket.emit('/discussion/full/tags/update', newTag);
        });
      } catch (err) {
        setError(error.response.data);
      } finally {
        setNewTag('');
      }
    }
  };

  useEffect(() => {
    document.title = `Small Group Discussion - ${discussion.name}`;

    axios.get('/api/unit/retrieve/order',
      { params: { order: discussion.unit } }
    ).then(res => {
      setTags(res.data.tags);
    });

    if (isApproved) {
      let tmpNotes = [];
      // pushing yups to temp array
      for (let note of notes) {
        if (note.vote === 'yup') {
          // pushing to full class
          let dateNow = new Date();
          tmpNotes.push({
            title: discussion.prompt,
            body: note.content,
            background: colorList[Math.floor(Math.random() * colorList.length)],
            date: dateNow.getMonth() + 1 + "/" + dateNow.getDate() + "/" + dateNow.getFullYear(),
            originId: note.noteId
          });
        }
      }
      // saving notes to db
      if (tmpNotes && tmpNotes.length) {
        try {
          axios.post('/api/answers/savebulknotes', {
            id: userId,
            labKey: `${discussion.name} (your group)`,
            newNotes: tmpNotes,
            navId: lessonId
          });
        } catch(err) {
          setError(err.response.data);
        }
      }
      // preventing multiple users emitting identical notes
      // only the user with finalized notes will push
      // to full class discussion
      // doing this last for "reasons"
      if (discussion.pushToFullClass && finalizedNotes) {
        for (let note of finalizedNotes) {
          note.vote = '';
          (async() => {
            try {
              const doc1 = await axios.post('/api/notes/save', {
                content: note.content,
                tags: note.tags,
                userId: note.userId,
                discussionId: discussion.fullClassDiscussionId,
                originId: note.noteId,
                originType: 'small group',
                originRoom: note.originRoom,
                ingredientIds: note.ingredientIds,
                combinedContent: note.combinedContent
              });
              const doc2 = await axios.post('/api/notePositions/save', {
                content: note.content,
                tags: note.tags,
                userId: note.userId,
                noteId: doc1.data._id,
                discussionId: discussion.fullClassDiscussionId,
                originId: note.noteId,
                originType: 'small group',
                x: pushMapping[note.originRoom].x,
                y: pushMapping[note.originRoom].y,
                originRoom: note.originRoom,
                ingredientIds: note.ingredientIds,
                combinedContent: note.combinedContent
              });
              socket.emit(`/discussion/full/push`, doc2.data);
            } catch (err) {
              dispatch({
                type: 'SET_ERROR',
                error: err.response.data
              });
            }
          })();
        }
      }
      socket.emit(`${room}/finish`, userId);
    }

    // only allowing students to join room
    if (auth_level === 1) {
      socket.emit(`${room}/join`,
        { userId, discussionId, room, name: fullName }
      )
    }

    socket.on(`${room}/finish`, () => {
      if (discussion.pushToFullClass) {
        history.push(`/fulldiscussion/note/${discussion.unit}/${discussion.fullClassDiscussionId}`)
      } else {
        history.push('/notebook');
      }
    });

    socket.on(`${room}/done`, (arg) => {
      dispatch({
        type: 'ADD_DONE_USER',
        payload: arg
      });
    });

    socket.on(`${room}/approved`, () => {
      // teacher notification updated: teacher approved
      handleTeacherNotification('teacher approval');
      // approval from teacher will finish the discussion
      setIsApproved(true);
    });

    socket.on(`${room}/rework`, () => {
      // teacher notification updated: teacher rework
      handleTeacherNotification('teacher rework');
      dispatch({ type: 'CLEAR_DONE_USER' });
      setOpenRequestApproval(false);
    });

    // propogate renunciate across the group
    socket.on(`${room}/groupRenunciate`, () => {
      setOpenRequestApproval(false);
    });

    // propogate request across the group (pops up modal)
    socket.on(`${room}/groupPropogate`, () => {
      setOpenRequestApproval(true);
    });

    socket.on(`${room}/join`, (arg) => {
      dispatch({
        type: 'SET_ROOM_USERS',
        payload: arg
      });
    });

    socket.on(`${room}/leave`, (arg) => {
      dispatch({
        type: 'REMOVE_ROOM_USER',
        payload: arg
      });
      dispatch({
        type: 'REMOVE_DONE_USER',
        payload: arg
      });
    });

    socket.on(`${room}/combined`, (arg) => {
      dispatch({
        type: 'UPDATE_NOTE_COMBINED',
        id: arg
      })
    });

    socket.on(`${room}/new`, (arg) => {
      dispatch({
        type: 'ADD_NOTE',
        payload: arg
      })
    });

    // pushing new tag on creation
    socket.on(`${room}/tags/update`, (arg) => {
      setTags(tags => [...tags, arg]);
    });

    socket.on(`${room}/move`, (targetUserId, targetDiscussionId, unit) => {
      if (targetUserId === userId) {
        window.removeEventListener('beforeunload', alertUser);
        window.removeEventListener('unload', handleAway);

        dispatch({
          type: 'REMOVE_ROOM_USER',
          payload: targetUserId
        });
        dispatch({
          type: 'REMOVE_DONE_USER',
          payload: targetUserId
        });
        // no target discussion will move to waiting room, intentional
        if (targetDiscussionId === undefined) {
          history.push(`/discussion`)
        } else if (unit) {
          history.push(`/fulldiscussion/note/${unit}/${targetDiscussionId}`)
        } else {
          history.push(`/discussion/${targetDiscussionId}`)
        }
        window.location.reload();
      }
    });
    // Logic for removing user if they navigate away from the page
    // Also refresh notification
    window.addEventListener('beforeunload', alertUser);
    window.addEventListener('unload', handleAway);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('unload', handleAway);
      handleAway();
    }
    // eslint-disable-next-line
  }, [isApproved]);

  return (
    <StyledIdeaWallBackground color={groupImageMapping[rawRoom].color}>
      <div className='group-discussion'>
        Question Prompt:
        <br />
        {discussion.prompt}
      </div>
      <div className='ellipse' />
      <img className='team' src={groupImageMapping[rawRoom].image} alt='soybean' />
      <div className='team-text'>Team {rawRoom}</div>
      <svg className='blob' xmlns="http://www.w3.org/2000/svg" width="100%" height="419" viewBox="0 0 1536 419" fill="none">
          <path d="M1397.06 388.599C696.729 229.607 28.5757 441.892 -221.41 302.566C-471.396 163.24 -481.415 -152.03 -179.287 -489.871C122.841 -827.713 1152.15 -617.131 1439.19 -403.839C1726.23 -190.547 2097.4 547.591 1397.06 388.599Z" fill="#E3A920"/>
      </svg>
      <svg className="blob-right" xmlns="http://www.w3.org/2000/svg" width="90%" height="131" viewBox="0 0 1372 131" fill="none">
        <path d="M33.3606 -184.61C122.25 -318.606 1204.47 -363.669 1388.52 -336.475C1572.56 -309.282 1625.23 -156.803 1442 -3.99979C1258.77 148.804 413.459 141.313 243.751 85.537C74.0437 29.7609 -55.5286 -50.6132 33.3606 -184.61Z" fill="#E0E0E0"/>
      </svg>
      <svg className="blob-right" xmlns="http://www.w3.org/2000/svg" width="25%" height="150" viewBox="0 0 304 150" fill="none">
        <path d="M1168.82 87.4003C716.925 46.1108 305.148 183.378 139.452 128.561C-26.2449 73.7447 -49.3532 -78.763 123.985 -258.218C297.324 -437.674 960.263 -388.198 1153.36 -299.379C1346.45 -210.561 1620.72 128.69 1168.82 87.4003Z" fill="#FDFDFD"/>
      </svg>
      <svg className="combine-zone combine-target" xmlns="http://www.w3.org/2000/svg" width="200" height="175" viewBox="0 0 200 175" fill="none">
        <path opacity="0.7" d="M130.254 9.98133C178.423 23.3337 208.34 66.8243 197.212 106.633C186.084 146.442 146.131 187.37 75.5482 170.116C28.6909 158.661 -7.82948 129.163 1.767 56.1888C20.5284 -14.1323 82.0845 -3.37099 130.254 9.98133Z" fill="white"/>
      </svg>
      <div className="label">Combine Your Notes Here</div>
      <Button
        primary
        small
        style={{left: '0', right: '0', top: '245px', margin:'0 auto'}}
        onClick={handleCombineOpen}
      >
        <div className="text-wrapper">Activate Combine Zone</div>
      </Button>
      <Button
        primary
        small
        style={{left: '0', right: '0', top: '340px', margin:'0 auto'}}
        onClick={handleResponseOpen}
      >
        <div className="text-wrapper">Add Note</div>
      </Button>
      <DropZone className="yup-target">
        <div className="sub-title">Yup Zone</div>
        { doneUsers.length < roomUsers.length && <Button
          style={{ position: 'absolute', right: '0', left: '0', margin: '0 auto', bottom: '50px'}}
          small
          onClick={handleSubmit}
          disabled={auth_level !== 1}
          >
          <div className="text-wrapper">I'm Done! <br /> {doneUsers.length} / {roomUsers.length}</div>
        </Button>}
        { doneUsers.length === roomUsers.length && <Button
            style={{ position: 'absolute', right: '0', left: '0', margin: '0 auto', bottom: '50px'}}
            small
            onClick={handleFinish}
            disabled={auth_level !== 1}
          >
          <div className="text-wrapper">We're Done!</div>
        </Button>}
      </DropZone>
      <SideBar
        handleGoHome={() => history.push('/notebook')}
        handleShowNotes={() => setShowNotes(true)}
        handleExit={() => history.push(`/discussion`)}
      />
      {auth_level === 1 && <NoteManager
        show={showNotes}
        close={closeNotes}
        navigation={navigation}
        ideaWall={true}
        responseModal={handleResponseOpen}
      />}
      { auth_level !== 1 && <Button
        primary
        style={{left: '0', right: '0', bottom: '50px', margin:'0 auto'}}
        onClick={() => window.close()}
      >
        <div className="text-wrapper">Return to Room View</div>
      </Button> }
      <Modal
        open={openCombine}
        // onClose={handleCombineClose}
        aria-labelledby="modal-modal-combine-form"
        aria-describedby="modal-modal-combine-notes"
      >
        <Box className={classes.modal}>
          <StudentCombineForm
            userId={userId}
            room={room}
            discussionId={discussionId}
            ingredientIds={ingredientIds}
            combinedContent={staged}
            handleClose={handleCombineClose}
            tags={tags}
            newTag={newTag}
            setNewTag={setNewTag}
            handleNewTag={handleNewTag}
          />
        </Box>
      </Modal>
      <Modal
        open={openResponse}
        // onClose={handleResponseClose}
        aria-labelledby="modal-modal-response"
        aria-describedby="modal-modal-form-response"
      >
        <Box className={classes.modal}>
          <StudentResponseForm
            userId={userId}
            room={room}
            noteText={noteText}
            discussionId={discussionId}
            originId={noteOriginId}
            handleNoteTextChange={handleNoteTextChange}
            handleClose={handleResponseClose}
            tags={tags}
            newTag={newTag}
            setNewTag={setNewTag}
            handleNewTag={handleNewTag}
          />
        </Box>
      </Modal>
      <Modal
        open={openRequestApproval}
        // onClose={handleResponseClose}
        aria-labelledby="modal-modal-teacher"
        aria-describedby="modal-modal-teacher-response"
      >
        <Box className={classes.modal}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={12} xl={12}>
              <Typography variant="h4">Please Wait for Teacher Approval</Typography>
              <Typography variant="subtitle1" align="center">({notes.filter(note => note.vote === "yup").length}) "yup" notes are pending...</Typography>
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              {/* <IconButton className={classes.deleteButton} onClick={handleRenunciateApproval}>
                <CloseIcon />
              </IconButton> */}
              { notes.filter(note => note.vote === "yup").map((note, index) =>
                <ListItem divider={true} key={index}>
                  <Typography variant="body1">
                    Note {index+1} content: {note.content}
                  </Typography>
                </ListItem>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
      { notes.map((note, index) => <StudentResponse
        key={index}
        index={index}
        discussionId={discussionId}
        note={note}
        socket={socket}
        room={room}
      />)}
    </StyledIdeaWallBackground>
  )
}
