import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    buttons: {
        marginRight: 15,
        fontSize: '27px',
        minWidth: '15em',
        maxWidth: '15em',
        minHeight: '4em',
        maxHeight: '4em',
        width: '15em',
        height: '4em'
    },
    link: {
        textDecoration: 'none',
        color: '#fff',
        '&:visited': {
            textDecoration: 'none',
            color: '#fff'
        }
    }
}))

export default function ResearchDashboard() {

    const classes = useStyles();
    const links = [
        {
            route: '/assignlesson',
            label: 'Assign Lesson'
        },
        {
            route: '/authoring',
            label: 'Discussion Authoring'
        },
        {
            route: '/labunlocker',
            label: 'Unlock Labs'
        },
        {
            route: '/createlabs/none',
            label: 'Activity Info'
        },
        {
            route: '/guestcreate',
            label: 'Guest Management'
        },
        {
            route: '/analyticdashview',
            label: 'Teacher Dashboards'
        },
        {
            route: '/roomview',
            label: 'Room View'
        },
        {
            route: '/createunit',
            label: 'Unit Info'
        },
        {
            route: '/users',
            label: 'Users'
        },
        {
            route: '/schools',
            label: 'Schools'
        },
        {
            route: '/datadownload',
            label: 'Data Download'
        },
        {
            route: '/seatingchart',
            label: 'Seating chart'
        }
    ]

    return (
        <Grid container spacing={1}>
            {links.map((link) => <Grid item xs={12} md={6} lg={4}>
                <Link className={classes.link} to={link.route}>
                    <Button className={classes.buttons} variant="contained" color="primary">{link.label}</Button>
                </Link>
            </Grid>)}
        </Grid>
    )
}