import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card, Paper, Modal, Button, TextField, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import socket from '../../utils/socket';
import axios from 'axios';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';


const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        height: 'auto',
        padding: 4,
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
      },
      noOverLay: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      },
}));


const columns = [
    { field: 'name', headerName: 'Name', width: 275 },
    // { field: 'userId', headerName: 'User Id', width: 225 },
];

export default function RoomViewTable({
    discussionId,
    group,
    userIds,
    setSelectionModel,
    selectionModel,
    handleApprove,
    handleRework,
    name,
    prompt,
    notifications
}) {
    const [open, setOpen] = useState(false);

    //menu
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const handleMenuClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const [newPrompt, setNewPrompt] = useState('');
    const [newName, setNewName] = useState('');

    //edit modal
    const [openEditModal, setEditModalOpen] = useState(false);
    const handleEditModalOpen = () => setEditModalOpen(true);
    const handleEditModalClose = () => {
        setEditModalOpen(false);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const classes = useStyles();
    const [error, setError] = useState(null);

    //red discussion finish color
    const [doneColor, setDoneColor] = useState(false);

    const handleView = (discussionId) => {
        const win = window.open(`/discussion/${discussionId}`, '_blank');
        win.focus();
    };

    const handleEdit = () => {
        handleMenuClose();
        handleOpen();
    };

    //Changing room display when no students are in room
    const CustomNoRowsOverlay = () => {
        return (
            <Typography className={classes.noOverLay}>No students in room</Typography>
        );
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            await axios.put('/api/discussion', {
                discussionId,
                prompt: newPrompt,
                name: newName
            });
        } catch (err) { setError(err.response.data); console.log(error) }
        handleClose();
    }

    useEffect(() => {
        setNewName(name);
        setNewPrompt(prompt);

        socket.on('/teacher/notifyTeacher', data => {
            if (data.includes(`/${group}`)) { setDoneColor(true) }
        });
    }, [discussionId])

    if (group === 'full') {
        return <></>
    }
    return (
        <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
            <Card sx={{  border: 3, borderColor:'primary.main', borderRadius: '10px'}}>
                <Card sx={{  height: 95, borderBottom: 3, borderColor:'primary.main', borderRadius: '5px',
                 backgroundColor: 'defaultBgcolor',...(doneColor==true && {backgroundColor: 'error.main'}) }}
                >
                    <Grid container spacing={1} xl={12}>
                        <Grid item xs={6} md={6} xl={6}>
                            <Typography
                                sx={{ fontSize: {sm:20, md:20, lg:18, xl:24}, mt:1, mb:1, ml:3 }}
                            >
                                {group}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} xl={6} container justifyContent='flex-end' >
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            aria-controls={menuOpen ? 'options-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={menuOpen ? 'true' : undefined}
                            onClick={(e) => handleMenuClick(e)}
                            sx={{ mr: 1 }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="options-menu"
                            aria-labelledby="options-button"
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={handleMenuClose}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                        >
                            <MenuItem onClick={() => handleView(discussionId)}>View</MenuItem>
                            <MenuItem onClick={() => handleEdit()}>Edit</MenuItem>
                        </Menu>
                        <Modal
                            open={open}
                            onClose={handleEditModalClose}
                            aria-labelledby="config-edit-modal-title"
                            aria-describedby="config-edit-modal-description"
                        >
                        <form
                            className={classes.modal}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                        >
                            <Paper className={classes.paper}>
                                <Grid container spacing={5}>
                                    <Grid item xs={12} md={12} xl={12}>
                                        <Typography variant="h4">
                                            Edit Discussion
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            For {group} group
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} xl={12}>
                                        <TextField
                                            id="standard"
                                            label="Discussion Name"
                                            value={newName}
                                            onChange={(event) => setNewName(event.target.value)}
                                            fullWidth
                                            required
                                            rows={2}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} xl={12}>
                                        <TextField
                                            id="standard"
                                            label="Question Prompt"
                                            value={newPrompt}
                                            onChange={(event) => setNewPrompt(event.target.value)}
                                            fullWidth
                                            required
                                            rows={2}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6} xl={6}>
                                        <Button
                                            onClick={() => handleClose()}
                                            variant="contained"
                                            size="small"
                                            color="error"
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} md={6} xl={6}>
                                        <Button
                                            type='submit'
                                            variant='contained'
                                            size='small'
                                            color='primary'
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </form>
                    </Modal>
                </Grid>
                {notifications && notifications.map((notification, index) => {if (notification.replace('/', '') == group) {
                    return <Grid key={index} item xs={12} xl={12} container wrap='nowrap' >
                            <Grid item  xs={6} md={6} xl={6} >
                                <Button
                                    onClick={() => handleApprove(notification) | setDoneColor(false)}
                                    variant="contained"
                                    size="small"
                                    sx={{ width:80, mb:1, ml:2}}
                                    >Approve
                                </Button>
                            </Grid>
                            <Grid item xs={6} md={6} xl={6} container justifyContent='flex-end' >
                                <Button
                                    onClick={() => handleRework(notification) | setDoneColor(false)}
                                    variant="contained"
                                    size="small"
                                    sx={{ width:80, mb:1, mr:2}}
                                >Rework
                                </Button>
                            </Grid>
                        </Grid>  }})}
                    </Grid>
                </Card>
                <div style={{ height: 250, width: "100%" }}>
                    { userIds && <DataGrid
                        rows={userIds}
                        columns={columns}
                        checkboxSelection
                        getRowId={(r) => r.userId}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                        keepNonExistentRowsSelected
                        hideFooter
                        disableSelectionOnClick
                        components={{
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                    /> }
                </div>
            </Card>
        </Grid>
    )
}