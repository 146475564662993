import React from 'react';
import axios from 'axios';
import { useGridRegisterStrategyProcessor } from '@mui/x-data-grid/hooks/core/strategyProcessing';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Checkbox from "@material-ui/core/Checkbox";
import Button from '@material-ui/core/Button';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';

const styles  = (theme) => ({
    pageMargin: {
        marginLeft: 275,
        marginTop: theme.spacing(3),
    },
    unitSelector: {
        minWidth: 150,
        marginLeft: 10,
    },
    tableHeader: {
        fontWeight: "bold",
    },
    accordionMargin: {
        '&&': {
            marginRight: theme.spacing(2),
        },
    },
    accordionSummary: {
        marginLeft: theme.spacing(1),
    },
});

class DataDownload extends React.Component{
    
    constructor(props) {
        
        super(props);
        this.state = {
            unitList: [], // Units from the backend
            selectedUnit: null, // The currently selected unit
            filterByUnitId: '', // selected lab that we're showing in the table
            schoolList: [], // List of schools and teachers
            filterByTeacherName: null, // Name of the selected teacher name that we're filtering on
            fetchingCSV: false,
        };
    }

    componentDidMount(){
        this.fetchLabs();
        // we don't need this for teachers because they can only see their own classes
        if (this.props.auth.user.access === 3) {
            this.fetchSchools();
        }
    }

    fetchLabs(){
        // Get all the units (aka navigation templates) from the backend
        axios.get("/api/dataDownload/getNavigationForDataDownload").then(response => {
            response.data.forEach((unit) => {
                unit.Labs.forEach((lab) => {
                    if (lab.type === "lab") {
                        lab.experimentChecked = false;
                        lab.analysisChecked = false;
                    }
                    else if (lab.type === "essay") {
                        lab.essayChecked = false;
                    }
                });
            });
            this.setState({
                unitList: response.data, 
                selectedUnit: response.data[0], 
                filterByUnitId: response.data[0]._id
            });
        })
        .catch(e =>{
            console.log(e);
        });
    }

    fetchSchools(){
        axios.get("/api/users/getAllTeachers").then(response => {
            let tempSchoolList = [];
            let schoolNum = -1;
            let i;
            let currentSchool = '';
            let teacher = null;
            // loop through the schools to build a list that is easier for the UI
            // we get an array of objects with school and teacher for each teacher
            // we are building an arry of objects with school and teacher list for each school
            for(i=0; i < response.data.length; i++){
                teacher = response.data[i];
                // if this is the first school or the school has changed, add a new item to our list
                // this works because data is ordered by school name
                if (i === 0 || currentSchool !== teacher.school) {
                    schoolNum++;
                    currentSchool = teacher.school;
                    tempSchoolList.push( { school: currentSchool, teacherList: [], checked: false });
                }
                // add the teacher to the school
                tempSchoolList[schoolNum].teacherList.push(
                    { 
                        teacherName: teacher.first_name + " " + teacher.last_name, 
                        _id: teacher._id,
                        checked: false
                    }
                );
            }
            this.setState({
                schoolList: tempSchoolList
            });
        })
        .catch(e =>{
            console.log(e);
        });
    }

    handleUnitChange = (event) => {
        let value = event.target.value;
        let newUnit = this.state.unitList.filter(item => {
            return item._id === value
        });
        // clear out any previous values for the selected unit
        newUnit[0].Labs.forEach((lab) => {
            if (lab.type === "lab") {
                lab.experimentChecked = false;
                lab.analysisChecked = false;
            }
            else if (lab.type === "essay") {
                lab.essayChecked = false;
            }
        });
        this.setState({
            filterByUnitId: value, 
            selectedUnit: newUnit[0]
        });
    }

    handleExpCheckBoxChange = (event, lab) => {
        let tempSelectedUnit = this.state.selectedUnit;
        tempSelectedUnit.Labs.forEach((tempLab) => {
            if (lab.labName === tempLab.labName) {
                tempLab.experimentChecked = !lab.experimentChecked;
            }
        });
        this.setState({
            selectedUnit: tempSelectedUnit
        })
    }

    handleAnalysisCheckBoxChange = (event, lab) => {
        let tempSelectedUnit = this.state.selectedUnit;
        tempSelectedUnit.Labs.forEach((tempLab) => {
            if (lab.labName === tempLab.labName) {
                tempLab.analysisChecked = !lab.analysisChecked;
            }
        });
        this.setState({
            selectedUnit: tempSelectedUnit
        })
    }

    handleEssayCheckBoxChange = (event, lab) => {
        let tempSelectedUnit = this.state.selectedUnit;
        tempSelectedUnit.Labs.forEach((tempLab) => {
            if (lab.labName === tempLab.labName) {
                tempLab.essayChecked = !lab.essayChecked;
            }
        });
        this.setState({
            selectedUnit: tempSelectedUnit
        })
    }

    handleTeacherCheckBoxChange = (event, teacher) => {
        let tempSchoolList = this.state.schoolList;
        tempSchoolList.forEach((school) => {
            school.teacherList.forEach((tempTeacher) => {
                if(tempTeacher._id === teacher._id) {
                    tempTeacher.checked = !teacher.checked;
                }
            })
        });
        this.setState({
            schoolList: tempSchoolList
        })
    }

    handleSchoolCheckBoxChange = (event, school) => {
        let tempSchoolList = this.state.schoolList;
        tempSchoolList.forEach((tempSchool) => {
            if(tempSchool.school === school.school){
                tempSchool.checked = !school.checked;
                tempSchool.teacherList.forEach((teacher) => {
                    teacher.checked = tempSchool.checked;
                })
            }
        });
        this.setState({
            schoolList: tempSchoolList
        })
    }

    getCSV() {
        this.setState({
            fetchingCSV: true
        })
        axios.post("/api/dataDownload/getCSVForDataDownload", {
            selectedUnit: this.state.selectedUnit,
            schoolList: this.state.schoolList
        }).then(response => {

            const fileName = "student_data.csv";
            const downloadLink = document.createElement('a');
            const data = new Blob([response.data], { type: "text/csv" });
            downloadLink.href = window.URL.createObjectURL(data);
            downloadLink.setAttribute('download', fileName);
            document.body.appendChild(downloadLink);
            downloadLink.click();

            this.setState({
                fetchingCSV: false
            })
        })
        .catch(e =>{
            console.log(e);
            this.setState({
                fetchingCSV: false
            })
        });
    }

    render(){
        const { classes } = this.props;
        const loggedInUserIsResearcher = this.props.auth.user.access === 3;
        return (
            <div className={classes.pageMargin}>
                <Grid justifyContent="space-between" container spacing={4}>
                    <Grid item xs={9}>
                        <Typography variant="subtitle1" component="h2" color="primary">Data</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        {this.state.fetchingCSV && <CircularProgress />}
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" color="primary" onClick={() => this.getCSV()}>Submit</Button>
                    </Grid>
                </Grid>
                <Accordion className={classes.accordionMargin}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
                         <Typography>By Lab</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControl className={classes.unitSelector} variant="outlined">
                            <InputLabel id="unit">Content Unit</InputLabel>
                            <Select
                                labelId="unit-select-label"
                                id="unit-select"
                                label="Content Unit"
                                onChange={this.handleUnitChange}
                                value={this.state.filterByUnitId}
                            >{
                                this.state.unitList.map((unit, i) => (
                                    <MenuItem key={unit._id} value={unit._id}>{unit.Lesson_Name}</MenuItem>
                                ))
                            }
                            </Select>
                        </FormControl>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHeader}>
                                            Lab
                                        </TableCell>
                                        <TableCell className={classes.tableHeader}>
                                            Page
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.selectedUnit && this.state.selectedUnit.Labs.map((lab, j) => (
                                        // This is an Immediately Invoked Function Expression (IIFE) so I could do the conditional
                                        (() => {
                                            if(lab.type === "lab") {
                                                return (
                                                    // This fragment is necessary because you can only return one element at the given level per function
                                                    <React.Fragment key={"lab" + j}>  
                                                        <TableRow key={"labPredict" + j}> 
                                                            <TableCell>{lab.labName}</TableCell>                                   
                                                            <TableCell>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox checked={lab.experimentChecked} 
                                                                            onChange={(e) => this.handleExpCheckBoxChange(e, lab)} />
                                                                    }
                                                                    label={lab.tabTitles.experiment}
                                                                />   
                                                            </TableCell> 
                                                        </TableRow>
                                                        <TableRow key={"labAnalysis" + j}>
                                                            <TableCell></TableCell>
                                                            <TableCell>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox checked={lab.analysisChecked} 
                                                                            onChange={(e) => this.handleAnalysisCheckBoxChange(e, lab)} />
                                                                    }
                                                                    label={lab.tabTitles.analysis}
                                                                />  
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                );
                                            }
                                            else if (lab.type === "essay") {
                                                return (
                                                    <TableRow key={"labEssay" + j}>
                                                        <TableCell>{lab.labName}</TableCell>
                                                        <TableCell>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox checked={lab.essayChecked} 
                                                                        onChange={(e) => this.handleEssayCheckBoxChange(e, lab)} />
                                                                }
                                                                label="Essay"
                                                            />  
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        })()
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
                { loggedInUserIsResearcher &&
                <Accordion className={classes.accordionMargin}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>
                         <Typography>By School Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHeader}>
                                            School
                                        </TableCell>
                                        <TableCell className={classes.tableHeader}>
                                            Teacher
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.schoolList.map((school, i) => (
                                        school.teacherList.map((teacher, j) => (
                                            <TableRow key={teacher._id}> 
                                                <TableCell>
                                                    {j !== 0 ? "" :
                                                        <FormControlLabel
                                                        control={
                                                            <Checkbox checked={school.checked} 
                                                                onChange={(e) => this.handleSchoolCheckBoxChange(e, school)} />
                                                        }
                                                        label={school.school}
                                                    />}
                                                </TableCell>                                   
                                                <TableCell>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox checked={teacher.checked} 
                                                                onChange={(e) => this.handleTeacherCheckBoxChange(e, teacher)} />
                                                        }
                                                        label={teacher.teacherName}
                                                    />
                                                </TableCell> 
                                            </TableRow>
                                        ))
                                    ))}
                                </TableBody>
                            </Table> 
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
                }
            </div>
        )
   }
}

useGridRegisterStrategyProcessor.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
   ) (withStyles(styles) (DataDownload));