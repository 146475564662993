import UnitTree from "./UnitTree"
import Editor from "../Editor";
import SimulationContent from "./SimulationContent";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

export default function ContentCreator() {

    const { lessonName } = useParams();

    const [pageChanged, setPageChanged] = useState(false);
    //page information
    const [pageID, setPageID] = useState("");
    const [pageObjs, setPageObjs] = useState([]);
    const [ansObjs, setAnswerObjs] = useState({});
    const [editorTest, setEditorTest] = useState([]);
    //lab information for publishing
    const [labIndex, setLabIndex] = useState(-1);
    const [tabType, setTabType] = useState("");
    const [refreshComponent, setRefresh] = useState(false);

    useEffect(() => {
        //if page is blank, don't attempt to retrieve anything
        if (pageID != "" && !pageID.includes("-Sim-")) {
            axios
            .post("/api/pagecreate/getpagedata", {
                page: pageID
            })
            .then(result => {
                setPageObjs(result.data.pageObjs);
                setAnswerObjs(result.data.ansObjs);
                let newEditor = <Editor 
                    key={pageID}
                    pageObjects={result.data.pageObjs}
                    answerObjects={result.data.ansObjs}
                    updatePage={updatePage}
                    setChanged={() => setPageChanged(true)}
                    publishPage={publishPage}
                />
                setEditorTest([newEditor]);
            })
            .catch(error => {
                console.log(error);
            })
        } else if (pageID.includes("-Sim-")) {
            axios
            .post("/api/pagecreate/getsim", {
                page: pageID
            })
            .then(result => {
                setPageObjs(result.data.pageObjs);
                setAnswerObjs(result.data.ansObjs);
                let simEditor = <SimulationContent pageObjs={result.data.pageObjs} ansObjs={result.data.ansObjs} updatePage={updateSimulation} publishPage={publishSimulation} />
                setEditorTest([simEditor])
            })
        } else {
            setEditorTest(<div style={{marginTop: -555, zIndex: 1}}></div>);
        }
    }, [pageID])

    const updatePage = (newPageObjs, newPageAns) => {
        axios
        .post("/api/pagecreate/updatepagedata", {
            pageID: pageID,
            newObjs: newPageObjs,
            newAnsObjs: newPageAns
        })
        .then(result => {
            //console.log(result);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const publishPage = (newPageObjs, newPageAns) => {
        let tab = tabType.toLowerCase();
        if (tab.trim().length == 0) {
            tab = "home";
        }
        axios
        .post("/api/pagecreate/publishpage", {
            pageID: pageID,
            newObjs: newPageObjs,
            newAnsObjs: newPageAns,
            labIndex: labIndex,
            tabType: tab,
            lesson: lessonName,
        })
        .then(result => {
            //console.log(result);
            setRefresh(!refreshComponent);
        })
        .catch(error => {
            console.log(error);
        });
    }

    const updateSimulation = (newPageObjs, newPageAns) => {
        axios
        .post("/api/pagecreate/updatesimulation", {
            pageID: pageID,
            newObjs: newPageObjs,
            newAnsObjs: newPageAns,
        })
        .then(result => {
            //console.log(result);
        })
        .catch(error => {
            console.log(error);
        })
    }

    const publishSimulation = (newPageObjs, newPageAns) => {
        axios
        .post("/api/pagecreate/publishsimulation", {
            pageID: pageID,
            newObjs: newPageObjs,
            newAnsObjs: newPageAns,
            labIndex: labIndex,
            lesson: lessonName,
        })
        .then(result => {
            //console.log(result);
            setRefresh(!refreshComponent);
        })
        .catch(error => {
            console.log(error);
        });
    }

    return (
        <div style={{marginLeft: 250, marginTop: 12}}>
            <UnitTree setPage={setPageID} setLabIndex={setLabIndex} setTabType={setTabType} refresh={refreshComponent} lesson={lessonName} />
            <div style={{zIndex: 0}}>
                {editorTest}
            </div>
        </div>
    )
}