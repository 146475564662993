/*
    Input Lengths:
        "short" - 15ch
        "long" - 25ch

    Adornments:
        String that will be at the end of the input box.
*/

//Material Components
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Radio, RadioGroup, FormControl, FormLabel, FormControlLabel } from "@mui/material";

const useStyles = makeStyles(() => ({
  inputStyle: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      display: "none",
    },
  },
}));

export default function PageSimInput(props) {
  const classes = useStyles();

  //standardizing length for normal inputs to be 20ch
  //const inputLength = props.inputLength === "long" ? "25ch" : "15ch";

  const generateAlphanumeric = () => {
    return (
      <TextField
        name={props.name}
        sx={{ m: 1, width: "20ch" }}
        value={props.value}
        onChange={props.handleChange}
        InputProps={{
        classes: { input: classes.inputStyle },
        endAdornment: (
          <InputAdornment sx={{ paddingLeft: 1 }}>
            {props.adornment}
          </InputAdornment>
        ),
        }}
        size="small"
      />
    )
  }

  const generateNumeric = () => {
    return (
      <TextField
        name={props.name}
        sx={{ m: 1, width: "20ch" }}
        value={props.value}
        onChange={props.handleChange}
        type="number"
        InputProps={{
        classes: { input: classes.inputStyle },
        endAdornment: (
          <InputAdornment sx={{ paddingLeft: 1 }}>
            {props.adornment}
          </InputAdornment>
        ),
        }}
        size="small"
      />
    )
  }

  const generateYN = () => {
    return (
      <FormControl>
      <RadioGroup
        row
        //aria-labelledby={"result-table-inputtype" + index}
        name={props.name}
        value={props.value}
        onChange={props.handleChange}
        style={{ paddingLeft: 10, marginTop: 6 }}
      >
        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="No" control={<Radio />} label="No" />
      </RadioGroup>
      </FormControl>
    )
  }

  const generateLong = () => {
    return (
      <TextField
        name={props.name}
        sx={{ m: 1, width: "40ch" }}
        value={props.value}
        onChange={props.handleChange}
        multiline
        rows={4}
      />
    )
  }

  const createInput = (inputType) => {
    if (inputType === "alpha") {
      return generateAlphanumeric();
    } else if (inputType === "num") {
      return generateNumeric();
    } else if (inputType === "yn") {
      return generateYN();
    } else if (inputType === "long") {
      return generateLong(props.question);
    } else {
      return null;
    }
  }

  return (
    <Grid container>
      <Grid item style={{ display: "flex", gap: "0.25rem" }}>
        <Typography sx={{ paddingTop: 2 }}>{props.question}</Typography>
        {createInput(props.inputType)}
      </Grid>
    </Grid>
  );
}
