import { useEffect, useState  } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Modal,
  Paper,
  Button,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import store from '../../store';
import FullClassAuthoring from './FullClassAuthoring';
import SmallGroupAuthoring from './SmallGroupAuthoring';
import Preview from './Preview';
import NavAppBar from './common/NavAppBar';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  menuItem: {
    textTransform: 'capitalize'
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    height: 'auto',
    border: '2px solid #000',
    padding: 4
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default function Authoring() {
  const classes = useStyles();
  const userId = store.getState().auth.user.id;
  const history = useHistory();

  // modal
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // data
  const [discussions, setDiscussions] = useState([]);
  const [units, setUnits] = useState([]);
  const [classInfo, setClassInfo] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // 403
    if (userId === undefined) {
      history.push(`/login`);
    }
    document.title = "Discussion Authoring";
    (async () => {
      try {
        const res1 = await axios.get('/api/discussion/all');
        setDiscussions(res1.data);
        const res2 = await axios.get('/api/unit');
        setUnits(res2.data);
        const res3 = await axios.post('/api/pagecreate/getclass')
        setClassInfo(res3.data);
      } catch (err) { setError(err.response.data) };
    })();
    // eslint-disable-next-line
  }, [message]);

  return (
    <Container maxWidth="xl">
      <NavAppBar
        to='/roomview'
        toLabel='Go To Room View'
      />
      <div style={{ marginTop: '100px' }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant='h4'>Small Group</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.paper}>
            <SmallGroupAuthoring
              classes={classes}
              classInfo={classInfo}
              handleSetMessage={setMessage}
              handleModalOpen={handleOpen}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant='h4'>Full Class</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.paper}>
            <FullClassAuthoring
              classes={classes}
              classInfo={classInfo}
              handleSetMessage={setMessage}
              handleModalOpen={handleOpen}
            />
          </AccordionDetails>
        </Accordion>
      </div>
      <Box
        sx={{
          minHeight: 100,
          marginTop: '2em'
        }}
      >
        <Typography variant='h4' align="center">Discussion Preview</Typography>
        <Preview
          units={units}
          discussions={discussions}
        />
      </Box>
      {/* TODO::some styling warnings with this modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-creation"
        aria-describedby="modal-modal-creation-success"
      >
        <Paper className={classes.modal}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h6" component="h2" align="center">
                {message}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleClose}
                fullWidth
              >
                Okay
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </Container>
  )
}