import React, { useEffect } from "react";
import store from "../../../store";
import axios from "axios";
import Button from "@mui/material/Button";
/* Select */
import { TextField, MenuItem } from "@mui/material";

import BarGraph from "./visuals/BarGraph";
import TimeChart from "./visuals/TimeChart";
import PyrEvalFeedback from "./visuals/PyrEvalFeedback";

export function AnalyticDashboard(props) {
  //overall state tree with auth and navigation
  let info = store.getState();

  //user id
  const [userid, setUser] = React.useState(info.auth.user.id);

  //react state
  // store class data
  const [classData, setClassData] = React.useState([]);
  //generating lab buttons
  const [labData, setLabData] = React.useState([]);
  const [labButtons, setLabButtons] = React.useState([]);
  //header for clarity
  const [selectedClassTitle, setClassTitle] = React.useState("");
  const [selectedLabTitle, setLabTitle] = React.useState("");
  //for data fetching
  const [selectedStudents, setStudents] = React.useState([]);
  const [selectedPages, setPages] = React.useState([]);
  const [tabType, setTabs] = React.useState([]);
  const [labInformation, setLabInfo] = React.useState("");
  const [classroomId, setClass] = React.useState("");
  const [essayVer, setEssayVer] = React.useState("");
  //for visuals
  const [analyzedData, setAnalyzed] = React.useState({});
  const [viz, setVisuals] = React.useState([]);
  //class roster
  const [classRoster, setRoster] = React.useState([]);

  const { leftMargin } = props;

  //get all the classes this teacher is included in
  useEffect(() => {
    let userToRetrieve;
    if (props.selectedTeacher != undefined && props.selectedTeacher != "") {
      userToRetrieve = props.selectedTeacher;
      setUser(props.selectedTeacher);
    } else {
      userToRetrieve = userid;
    }
    axios
      .post("/api/analytics/getclasses", {
        user: userToRetrieve,
      })
      .then(function (response) {
        setClassData(response.data);
        setTabs([]);
        setLabTitle("");
        setVisuals([]);
        setClassTitle("");
        setLabTitle("");
        setLabInfo("");
        setClass("");
        setLabButtons([]);
      });
  }, [props.selectedTeacher]);

  useEffect(() => {
    if (selectedLabTitle.toLowerCase().indexOf("essay") != -1) {
      setVisuals([<PyrEvalFeedback roster={classRoster} data={analyzedData} version={essayVer} essayNumber={selectedLabTitle} />]);
    } else {
      if (Object.keys(analyzedData).length != 0) {
        let graphs = [];
        //Generate graphs
        for(let i = 1; i <= Object.keys(analyzedData).length; i++) {
          //bad, refactor this.
          let objKey = getKeyByValue(analyzedData, i);
          let newGraph = <BarGraph values={analyzedData[objKey]} />;
          let timeChart = null;
          /*
          if (analyzedData[objKey]['time'].length > 0) {
            timeChart = <TimeChart values={analyzedData[objKey]} />;
          }
          */
          graphs.push(newGraph)
          //graphs.push(timeChart);
        }
        setVisuals(graphs);
      } else {
        setVisuals([]);
      }
  }
  }, [analyzedData]);

  useEffect(() => {
    if (selectedLabTitle != "" && selectedLabTitle.toLowerCase().indexOf("essay") === -1) {
      let tabButtons = [<Button style={{ marginLeft: 20, backgroundColor: "#03998a" }} variant="contained" onClick={() => {generateAnalytics("Experiment")}}>Experiment</Button>, <Button style={{ marginLeft: 15, backgroundColor: "#03998a" }} variant="contained" onClick={() => {generateAnalytics("Analysis")}}>Analysis</Button>];
      setTabs(tabButtons);
    } else if (selectedLabTitle.toLowerCase().indexOf("essay") !== -1) {
      let tabButtons = [<Button style={{ marginLeft: 20, backgroundColor: "#03998a" }} variant="contained" onClick={() => {generateAnalytics("Original")}}>Original</Button>, <Button style={{ marginLeft: 15, backgroundColor: "#03998a" }} variant="contained" onClick={() => {generateAnalytics("Revised")}}>Revised</Button>];
      setTabs(tabButtons);
    } else {
      setTabs([]);
    }
  }, [selectedLabTitle]);

  //helper
  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key]['Q'] === value);
  }

  function generateRoster(classId) {
    axios.post("/api/analytics/getroster", {
      classId: classId
    }).then(response => {
      setRoster(response.data);
    }).catch(error => {
      console.log(error);
    });
  }

  function generateAnalytics(tabToRetrieve) {
    if (tabToRetrieve === "Original" || tabToRetrieve === "Revised") {
      setEssayVer(tabToRetrieve);
      axios
        .post("/api/analytics/getessaydata", {
          lab: labInformation.replaceAll(" ", "_"),
          version: tabToRetrieve,
          classroom: classroomId
        })
        .then(response => {
          setAnalyzed(response.data);
        }).catch(error => {
          console.log(error);
        })
    } else {
      setEssayVer("");
      axios
        .post("/api/analytics/getdata", {
          classroom: classData[0]._id,
          lab: labInformation.replaceAll(" ", "_"),
          page: tabToRetrieve,
          id: userid
        })
        .then(response => {
          axios.post("https://sciwrite-flask.wcer.wisc.edu/analyze",
            response.data
          )
          .then(newResponse => {
            if (newResponse.data === 'No Data') {
              setAnalyzed([]);
            } else {
              setAnalyzed(newResponse.data);
            }
          })
          .catch(error => console.log(error));
        })
        .catch((error) => console.log(error))
    }
  }

  const generateLabButtons = (lData) =>{
    var counter = 0;
    var lButtons = [];
    lData.Labs.forEach((lab) => {
      if (counter % 4 === 0) {
        lButtons.push(<br key={counter} />);
        lButtons.push(<br key={counter - 1} />);
      }
      //get experiment and analysis
      var pages = [];
      if (lab.type === "lab") {
        pages.push(lab.pages.experiment);
        pages.push(lab.pages.analysis);
      } else {
        pages.push(lab.pages.overview);
      }
      lButtons.push(
        <Button
          key={"lab" + counter}
          style={{ marginLeft: 20, backgroundColor: "#71EFE9", color:"#000000" }}
          variant="contained"
          onClick={() => {
            setLabTitle(" --- " + lab.labName);
            setPages(pages);
            setLabInfo(lab.labName);
            setVisuals([]);
          }}
        >
          {lab.labName}
        </Button>
      );
      counter = counter + 1;
    });
    return lButtons;
  }

  function getLabs(lessonId) {
    let labData = [];
    axios
      .post("/api/analytics/getlabs", {
        lesson: lessonId,
      })
      .then(function (response) {
        setLabData(response.data[0]);
        var lab_buttons = generateLabButtons(response.data[0]);
        setLabButtons(lab_buttons);
      });
  }

  function generateClassButtons(cData) {
    var classButtons = [];
    cData.forEach((classValue) => {
      classButtons.push(
        <Button
          key={classValue.class_number +"-class"}
          style={{ marginLeft: 20 }}
          variant="contained"
          color="primary"
          onClick={() => {
            setTabs([]);
            setLabTitle("");
            getLabs(classValue.lessons);
            setClassTitle(classValue.class_number);
            setStudents(classValue.students);
            generateRoster(classValue._id);
            setClass(classValue._id);
            setVisuals([]);
          }}
        >
          {classValue.class_number}
        </Button>
      );
    });
    return classButtons;
  }

  const classButtons = generateClassButtons(classData);

  return (
    <div style={{marginTop: info.auth.theme.material.spacing(3), marginLeft: leftMargin}}>
      <h2 align="center">
        {selectedClassTitle}
        {selectedLabTitle}
      </h2>
      {classButtons}
      <br />
      {labButtons}
      <br/>
      <br/>
      {tabType}
      <br />
      <br />
      {viz}
    </div>
  );
}

// Set default left margin if not passed in as a prop
AnalyticDashboard.defaultProps = {
  leftMargin: 275
}

export default AnalyticDashboard;