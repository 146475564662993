import axios from 'axios';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import StudentResponseForm from "./StudentResponseForm";
import {
  Container,
  Tooltip,
  Typography,
  AppBar,
  IconButton,
  Toolbar,
  Snackbar
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import CloseIcon from '@mui/icons-material/Close';
import socket from '../../utils/socket';
import store from '../../store';
import { logoutUser } from "../../actions/authActions";

const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(2),
    textAlign: 'center',
    alignItems: 'center',
    alignContent: 'center',
    color: theme.palette.text.secondary,
  },
  appBar: {
    zIndex: theme.zIndex.drawer - 1
  },
  paper: {
    marginTop: '75px',
    padding: theme.spacing(2),
    textAlign: 'center'
  },
}));

export default function FullDiscussionNote({ match }) {
  const discussionId = match.params.id;
  const order = match.params.unit;
  const classes = useStyles();
  const user = store.getState().auth.user;
  const userId = user.id;
  const history = useHistory();
  const room = "/discussion/full";
  const fullName = `${user.first_name} ${user.last_name}`;

  const [noteText, setNoteText] = useState('');
  const [newTag, setNewTag] = useState('');
  const [tags, setTags] = useState([]);
  const [error, setError] = useState('');
  const [discussion, setDiscussion] = useState({});
  const [open, setOpen] = useState(false);

  const handleNewTag = (e) => {
    e.preventDefault();
    if (newTag) {
      try {
        axios.put('/api/unit/tag', {
          order, newTag
        }).then((res) => {
          // pushing tag through socket.on for ALL users
          // TODO::update state locally then push to other users
          socket.emit(`${room}/tags/update`, newTag);
        });
      } catch (err) {
        setError(error.response.data);
      } finally {
        setNewTag('');
      }
    }
  };

  const handleLogoutUser = e => {
    e.preventDefault();
    store.dispatch(logoutUser());
    // glitchy, had to add this.
    history.push('/login');
  }

  const handleOpen = () => {
    setOpen(true);
    setNoteText('');
  };

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  useEffect(() => {
    document.title = `Full Class Discussion`;

    // 403 and refuse socket connection
    if (userId === undefined) {
      history.push(`/login`);
    } else {
      socket.connect();
    }

    try {
      axios.get('/api/discussion',
        { params: { data: discussionId } }
      ).then(res => {
        setDiscussion(res.data);
      });
    } catch (err) {
      setError(err.response.data)
    }

    try {
      axios.get('/api/unit/retrieve/order',
        { params: { order } }
      ).then(res => {
        setTags(res.data.tags);
      });
    } catch (err) {
      setError(err.response.data)
    }

    socket.on(`${room}/tags/update`, (arg) => {
      setTags(tags => [...tags, arg]);
    });

    return () => {
      socket.disconnect();
    }
  }, []);

  return (
    <Container className={classes.paper}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Full Class Discussion | Question Prompt: {discussion.prompt}
          </Typography>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
          <Typography variant="h6" sx={{ mr: 1 }}>
            Signed in as: {fullName}
          </Typography>
          <Tooltip title="Return Home">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
              onClick={() => history.push('/')}
            >
              <HomeIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Logout">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
              onClick={handleLogoutUser}
            >
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <StudentResponseForm
        userId={userId}
        discussionId={discussionId}
        room={room}
        noteText={noteText}
        handleNoteTextChange={(e) => setNoteText(e.target.value)}
        handleClose={handleOpen}
        tags={tags}
        newTag={newTag}
        setNewTag={setNewTag}
        handleNewTag={handleNewTag}
      />
      <div>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message="Note sent!"
          action={action}
        />
      </div>
    </Container>
  )
}