import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import axios from 'axios';
import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import store from "../../../../store";

const useStyles = makeStyles((theme) => ({
  buttons: {
      marginRight: 15,
      fontSize: '27px',
  },
  link: {
    textDecoration: 'none',
    color: '#fff',
      '&:visited': {
        textDecoration: 'none',
        color: '#fff'
      }
  }
}))

export function GroupToClassKnowledgeWall(props) {

    let navigation = store.getState().navigation.navigation;

    const [discussions, setDiscussions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const classes = useStyles();

    useEffect(() => {
      try {
        axios.get('/api/discussion/name/class/groups',
          { params: { name: props.prompt, classId: navigation.Classroom } }
        ).then(res => {
          setDiscussions(res.data);
          setLoading(false);
        });
      } catch(err) {
        setError(error.response.data);
      }
    }, []);

    if (loading) return (<></>)
    return (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3}}>
        {discussions.map((discussion, index) => {
          return (
            <Grid key={index} item xs={4}>
              <Button key={index} variant="contained">
                <Link key={index} className={classes.link} to={'/discussion/'+discussion._id} target="_blank" rel="noopener noreferrer">{discussion.groupNumber}</Link>
              </Button>
            </Grid>
          )
        })}
      </Grid>
    )
}

export default GroupToClassKnowledgeWall;