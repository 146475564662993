import styled from 'styled-components';
import Home from '../../../assets/img/Home.png'
import Exit from '../../../assets/img/Exit.png'
import HomeText from '../../../assets/img/HomeText.png'
import Notes from '../../../assets/img/Notes.png'

export const Bar = styled.div`
  height: 100%;
  position: fixed;
  width: 50px;
  background-color: #D9D9D9;
}`;

const NavButton = styled.div`
  background-color: ${props => props.dark ? "#D9D9D9" : "#ffffff"};
  height: 150px;
  cursor: pointer;
  width: 50px;

  & .caption {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    opacity: 0.5;
    padding-top: 12px;
  }

  & img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
  }

  &:active {
    background-color: ${props => props.dark ? "#e6e6e6" : "#f2f2f2"};
  }
`;

const ExitNavButton = styled(NavButton)`
  bottom: 0;
  position: absolute;
`;

export const SideBar = ({ handleGoHome, handleShowNotes, handleExit }) => {
  return (
    <Bar>
      <NavButton
        key={'home'}
        dark
        onClick={handleGoHome}
      >
        <img src={Home} alt='home icon' />
        <div className='caption'>Home</div>
      </NavButton>
      <NavButton
        key={'notes'}
        onClick={handleShowNotes}
      >
        <img src={Notes} alt='note icon' />
        <div className='caption'>Show Notes</div>
      </NavButton>
      <ExitNavButton
        key={'exit'}
        dark
        onClick={handleExit}
      >
        <img src={Exit} alt='exit icon' />
        <div className='caption'>Exit</div>
      </ExitNavButton>
    </Bar>
  )
}