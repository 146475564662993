import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import SchoolIcon from '@mui/icons-material/School';
import PushPinIcon from '@mui/icons-material/PushPin';
import { Typography } from '@material-ui/core';
export default function Preview ({ units, discussions }) {
  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 540,
        bgcolor: 'background.paper'
      }}
    >
      <ListItem>
        <ListItemAvatar>
          <Avatar aria-label="order">
            #
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary='Discussion Name | Prompt'
          secondary={
            <Stack direction="row" spacing={1}>
              <Typography variant='body1'>Possible Components: </Typography>
              <Chip icon={<TagFacesIcon />} label="Small Group" variant="outlined" color="primary" />
              <Chip icon={<SchoolIcon />} label="Full Class" variant="outlined" color="secondary" />
              <Chip icon={<PushPinIcon />} label="Push to Full" variant="outlined" color="info" />
            </Stack>
          }
        />
      </ListItem>
      {(units).map(({order, name, prompt}, index) => (
        <React.Fragment key={index}>
          <ListItem>
            <ListItemAvatar>
              <Avatar aria-label="order">
                {order}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${name} | ${prompt}`}
              secondary={
                <Stack direction="row" spacing={1}>
                  { discussions.filter(d => d.groupNumber !== 'full' & d.unit === order).length > 0 && <Chip icon={<TagFacesIcon />} label="Small Group" variant="outlined" color="primary" /> }
                  { discussions.filter(d => d.groupNumber === 'full' & d.unit === order).length > 0 && <Chip icon={<SchoolIcon />} label="Full Class" variant="outlined" color="secondary" /> }
                  { discussions.filter(d => d.groupNumber !== 'full' & d.unit === order & d.pushToFullClass).length > 0 && <Chip icon={<PushPinIcon />} label="Push to Full" variant="outlined" color="info" /> }
                </Stack>
              }
            />
          </ListItem>
          {index + 1 > units.length && <Divider variant="inset" component="li" />}
        </React.Fragment>
      ))}
    </List>
  )
}