import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";


class Vidyamap extends Component {

    render() {
        //const { user } = this.props.auth;

        return(
            <div>
                <form action='https://vidyamap.staging.wceruw.org/#/' method='POST'>
                    <textarea style={{marginLeft: -600}} name='Authorization' id='Authorization' rows="6" cols="50" defaultValue="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjIwMDc4NTQ3NzksInVzZXJuYW1lIjoiamFyZWQiLCJpYXQiOjE2OTIyMzU2MDJ9.4Y33p5S9k5aQPVZP7c6GEBqJXWKL32NQq0rlj9wxW5c"></textarea>
                    <br />
                    <button style={{ marginLeft: 250, marginTop: 50, height: 100, width: 200 }} type='submit'>Proceed to Vidyamap!</button>
                </form>
            </div>
        );
    }
}

Vidyamap.propTypes = {
    auth: PropTypes.object.isRequired,
    lesson: PropTypes.object.isRequired,
    navigation: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    lesson: state.lesson,
    navigation: state.navigation
});

export default connect(
    mapStateToProps
) (Vidyamap);