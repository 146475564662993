import styled from 'styled-components';

export const DropZone = styled.div`
  height: 68%;
  width: 270px;
  position: fixed;
  background-color: #66822B;
  border-radius: 57.4px;
  bottom: 25px;
  right: 50px;

  & .sub-title {
    color: #ffffff;
    font-family: "Inter-Bold", Helvetica;
    font-size: 25.9px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    margin-top: 15px;
  }
}
`;