import styled from 'styled-components';

export const Button = styled.button`
  height: ${props => props.small ? "43px" : "57px"};
  width: ${props => props.small ? "130px" : "154px"};
  background-color: ${props => props.primary ? "#e3a920" : "#c0ce6d"};
  border-radius: 40.43px;
  position: fixed;
  cursor: pointer;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  &:hover {
    background-color: ${props => props.primary ? "#cc9719" : "#799933"};
  }
  &:active {
    background-color: ${props => props.primary ? "#9f7514" : "#88ac39"};
  }

  & .text-wrapper {
    color: #ffffff;
    font-family: "Inter-Bold", Helvetica;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
  }
`;