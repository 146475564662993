import axios from "axios";
import { useState, useEffect, createRef } from "react";
import { List, ListItem, ListItemButton, ListItemText, Link, IconButton, Box, ListSubheader, Dialog, DialogContent, DialogContentText, DialogActions, Button, DialogTitle, TextField } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ScienceIcon from "@mui/icons-material/Science";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddIcon from '@mui/icons-material/Add';

export default function UnitCreator() {

    const [units, setUnits] = useState([]);
    const [unitUpdate, setUpdate] = useState(false);
    const [selectedUnit, setSelected] = useState("");
    //Dialog for Editing
    const [editDialog, setEditDialog] = useState(false);
    const [unitError, setError] = useState(false);
    const [unitErrorText, setErrorText] = useState("");
    const editUnitName = createRef();
    //Dialog for Copying
    const [copyDialog, setCopyDialog] = useState(false);
    const copyUnitName = createRef();
    const [unitID, setUnitID] = useState("");
    //Delete functionality
    const [deleteDialog, setDeleteDialog] = useState(false);
    //New Unit functionality
    const [newDialog, setNewUnitDialog] = useState(false);
    const newUnitName = createRef();
    //Simulation functionality
    const [simDialog, setSimDialog] = useState(false);
    const simURL = createRef();

    useEffect(() => {
        axios
        .post("/api/pagecreate/retrieveunits")
        .then(result => {
            console.log(result);
            setUnits(result.data);
        })
        .catch(error => {
            console.log(error);
        })
    }, [unitUpdate]);

    function generateUnitList() {
        let unitList = [];
        if (units.length > 0) {
            units.forEach(unit => {
                unitList.push(
                    <ListItem>
                        <ListItemButton key={unit.Lesson_Name} component={Link} href={"/createcontent/" + unit.Lesson_Name}>
                            <ListItemText primary={unit.Lesson_Name} />
                        </ListItemButton>
                        <IconButton edge="end" aria-label="edit" onClick={() => {setSelected(unit.Lesson_Name); setUnitID(unit.Lesson_Id); setEditDialog(true);}}>
                            <EditIcon />
                        </IconButton>
                        <IconButton style={{marginLeft: 10}} edge="end" aria-label="labs" onClick={() => {setSelected(unit.Lesson_Name);  setSimDialog(true);}}>
                            <ScienceIcon />
                        </IconButton>
                        <IconButton style={{marginLeft: 10}} edge="end" aria-label="copy" onClick={() => {setSelected(unit.Lesson_Name); setUnitID(unit.Lesson_Id); setCopyDialog(true);}}>
                            <ContentCopyIcon />
                        </IconButton>
                        <IconButton style={{marginLeft: 10}} edge="end" aria-label="delete" onClick={() => {setSelected(unit.Lesson_Name); setDeleteDialog(true);}}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                )
            })
            let unitViz = [
                <Box sx={{ width: '100%', maxWidth: 450, bgcolor: 'background.paper' }}>
                    <List
                        aria-aria-labelledby="list-subheader"
                        subheader={
                            <ListSubheader component="div" id="list-subheader">
                                Unit List
                                <IconButton sx={{marginLeft: 1, marginTop: -0.5}} edge="end" aria-label="edit" onClick={() => setNewUnitDialog(true)}>
                                    <AddIcon />
                                </IconButton>
                            </ListSubheader>
                        }
                    >
                        {unitList}
                    </List>
                </Box>]
            return unitViz;
        } else {
            let unitViz = [
                <Box sx={{ width: '100%', maxWidth: 450, bgcolor: 'background.paper' }}>
                    <List
                        subheader={
                            <ListSubheader component="div" id="list-subheader">
                                Unit List
                                <IconButton sx={{marginLeft: 1, marginTop: -0.5}} edge="end" aria-label="edit" onClick={() => setNewUnitDialog(true)}>
                                    <AddIcon />
                                </IconButton>
                            </ListSubheader>
                        }
                    >
                    </List>
                </Box>]
            return unitViz;
        }
    }

    function checkUnitName(newName) {
        if (newName.trim().length == 0) {
            setErrorText("You cannot have an empty name for the unit.");
            setError(true);
            return false;
        } else {
            let unitNames = units.map(a => a.Lesson_Name);
            if (unitNames.includes(newName)) {
                setErrorText("You cannot have the same name as another unit.");
                setError(true);
                return false
            } else {
                setErrorText("");
                setError(false);
                return true;
            }
        }
    }

    //change the unit name
    function editUnit() {
        let newName = editUnitName.current.value;
        let check = checkUnitName(newName);
        if (check) {
            let splitName = newName.split(" ");
            let newID = "";
            splitName.forEach(word => {
                newID = newID + word[0];
            });
            axios
            .post("/api/pagecreate/editunitname", {
                lessonName: selectedUnit,
                newName: newName,
                newID: newID,
                oldID: unitID,
            })
            .then(result => {
                //console.log(result);
                setEditDialog(false);
                setSelected(""); 
                setError(false); 
                setErrorText("");
                setUnitID("");
                setUpdate(!unitUpdate);
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    //copy a copy
    function copyUnit() {
        let copyName = copyUnitName.current.value;
        let check = checkUnitName(copyName);
        if (check) {
            let splitName = copyName.split(" ");
            let copyID = "";
            splitName.forEach(word => {
                copyID = copyID + word[0];
            });
            axios
            .post("/api/pagecreate/copyunit", {
                lessonName: selectedUnit,
                newName: copyName,
                newID: copyID,
                oldID: unitID,
            })
            .then(result => {
                //console.log(result);
                setSelected(""); 
                setError(false); 
                setErrorText("");
                setUnitID(""); 
                setCopyDialog(false);
                setUpdate(!unitUpdate);
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    function deleteUnit() {
        axios
        .post("/api/pagecreate/deleteunit", {
            unit: selectedUnit
        })
        .then(result => {
            //console.log(result);
            setSelected("");
            setDeleteDialog(false);
            setUpdate(!unitUpdate);
        })
        .catch(error => {
            console.log(error);
        })
    }

    function createNewUnit() {
        let newName = newUnitName.current.value;
        let check = checkUnitName(newName);
        if (check) {
            let splitName = newName.split(" ");
            let newID = "";
            splitName.forEach(word => {
                newID = newID + word[0];
            });
            axios
            .post("/api/pagecreate/addnewunit", {
                unit: newName,
                unitId: newID
            })
            .then(result => {
                //console.log(result);
                setNewUnitDialog(false);
                setUpdate(!unitUpdate);
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    function checkURL(urlToCheck) {
        if (urlToCheck.trim().length == 0) {
            setErrorText("You cannot use a blank URL.");
            setError(true);
            return false;
        } else {
            //check if url format
            try {
                new URL(urlToCheck);
                setErrorText("");
                setError(false);
                return true;
            } catch (err) {
                setErrorText("You entered an invalid URL.");
                setError(true);
                return false;
            }
        }
    }

    //assigning URL
    function assignURL() {
        let simulation = simURL.current.value;
        let check = checkURL(simulation);
        if (check) {
            //post it
            axios
            .post("/api/pagecreate/assignsimtoall", {
                unit: selectedUnit,
                simURL: simulation
            })
            .then(result => {
                //console.log(result);
                setSelected("");
                setSimDialog(false);
                setError(false);
                setErrorText("");
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    return (
        <div style={{marginLeft: 250, marginTop: 12}}>
            {generateUnitList()}
            <Dialog
                open={editDialog}
                onClose={() => {setEditDialog(false); setSelected("")}}
                aria-labelledby="alert-edit-tab-title"
                aria-describedby="alert-edit-tab-dialog"
            >
                <DialogTitle id="alert-edit-tab-title">
                    Edit Unit: {selectedUnit}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-edit-tab-description">
                        <br/>
                        <TextField
                            error={unitError}
                            id="outlined-lab-title"
                            label="Unit Title"
                            defaultValue={selectedUnit}
                            inputRef={editUnitName}
                            helperText={unitErrorText}
                            />
                        <br/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setSelected(""); setError(false); setErrorText(""); setEditDialog(false);}}>Cancel</Button>
                    <Button variant="contained" onClick={() => editUnit()}>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={copyDialog}
                onClose={() => {setCopyDialog(false); setSelected("")}}
                aria-labelledby="alert-copy-tab-title"
                aria-describedby="alert-copy-tab-dialog"
            >
                <DialogTitle id="alert-copy-tab-title">
                    Copy Unit: {selectedUnit}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-copy-tab-description">
                        <br/>
                        <TextField
                            error={unitError}
                            id="outlined-lab-title"
                            label="Unit Title"
                            defaultValue={selectedUnit}
                            inputRef={copyUnitName}
                            helperText={unitErrorText}
                            />
                        <br/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setSelected(""); setError(false); setErrorText(""); setUnitID(""); setCopyDialog(false);}}>Cancel</Button>
                    <Button variant="contained" onClick={() => copyUnit()}>Make Copy</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteDialog}
                onClose={() => {setDeleteDialog(false); setSelected("")}}
                aria-labelledby="alert-delete-unit-title"
                aria-describedby="alert-delete-unit-dialog"
            >
                <DialogTitle id="alert-delete-unit-title">
                    Deleting Unit: {selectedUnit}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-delete-unit-description">
                        Are you sure you would like to delete the unit '{selectedUnit}'? Once you delete this unit, it cannot be restored. If you are sure you want to proceed with deleting this unit, click Delete.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setSelected(""); setDeleteDialog(false);}}>Cancel</Button>
                    <Button variant="contained" sx={{backgroundColor: "red", text: "white"}} onClick={() => deleteUnit()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={newDialog}
                onClose={() => {setNewUnitDialog(false);}}
                aria-labelledby="alert-new-unit-title"
                aria-describedby="alert-new-unit-dialog"
            >
                <DialogTitle id="alert-new-unit-title">
                    Create New Unit
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-new-unit-description">
                        The new unit must have a different name than the other units, and the name cannot be blank.
                        <br/>
                        <br/>
                        <TextField
                            error={unitError}
                            id="outlined-new-unit-title"
                            label="Unit Title"
                            defaultValue=""
                            inputRef={newUnitName}
                            helperText={unitErrorText}
                            />
                        <br/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setError(false); setErrorText(""); setNewUnitDialog(false);}}>Cancel</Button>
                    <Button variant="contained" onClick={() => createNewUnit()}>Create Unit</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={simDialog}
                onClose={() => {setSimDialog(false);}}
                aria-labelledby="alert-sim-unit-title"
                aria-describedby="alert-sim-unit-dialog"
            >
                <DialogTitle id="alert-sim-unit-title">
                    Assign Simulation to Unit
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-sim-unit-description">
                        If you're planning to use one simulation for the entire unit, you can enter the unit here and have it assigned to all the lab pages. Once you click Assign URL, the pages will also be published, to make them as complete.
                        <br/>
                        <br/>
                        <TextField
                            error={unitError}
                            id="outlined-sim-unit-title"
                            label="Simulation URL"
                            defaultValue=""
                            inputRef={simURL}
                            helperText={unitErrorText}
                            style={{width: 450}}
                            />
                        <br/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setError(false); setErrorText(""); setSelected(""); setSimDialog(false);}}>Cancel</Button>
                    <Button variant="contained" onClick={() => assignURL()}>Assign URL</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}