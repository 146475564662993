import { useState, useRef, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Draggable from 'react-draggable';
import axios from 'axios';
import store from '../../store';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { useMutationObserver } from "rooks";
import throttle from "../../utils/Throttle";

const useStyles = makeStyles((theme) => ({
  noPointerEvents: {
    pointerEvents: 'none'
  },
  ideaColor: {
    borderRadius: '36px',
    border: '16.17px solid #E3A920',
    background: '#E3A920',
    boxShadow: '0px 3.23401px 3.23401px 0px rgba(0, 0, 0, 0.25)',
    color: '#fff',
    fontSize: '20px',
    margin: '10px',
    padding: '10px',
    resize: 'both',
    overflow: 'hidden',
    position: "absolute",
    zIndex: 2
  },
  ideaText: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Lato',
    fontSize: '20px',
    fontStyle: 'normal',
    lineHeight: 'normal',
    textDecorationLine: 'underline',
    fontWeight: '800'
  },
  dragHandle: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '0 auto',
    bottom: '5px',
    width: '70px',
    height: '15px',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    opacity: 0.5,
  }
}));

export default function IdeaSpace({ idea, discussionId }) {
  const ideaRef = useRef();
  const classes = useStyles();
  const didMountRef = useRef(false);
  const userId = store.getState().auth.user.id;
  const [state, setState] = useState({
    activeDrags: 0,
    x: idea.x,
    y: idea.y,
    width: idea.width,
    height: idea.height
  });
  const [error, setError] = useState(null);

  const onStart = () => {
    setState({...state, activeDrags: ++state.activeDrags});
  };

  const onDrop = (e, position) => {
    e.preventDefault();
    if (!state.activeDrags) { return }
    const {x, y} = position;
    setState({...state, activeDrags: 0, x, y});
  };

  const observeMutation = mutation => {
    if (mutation[0].type === 'attributes' && mutation[0].attributeName === 'style') {
      let { width, height } = ideaRef.current.style;
      setState({...state, width, height});
    }
  };

  useMutationObserver(ideaRef, throttle(observeMutation, 250));

  useEffect(() => {
    if (didMountRef.current) {
      try {
        axios.post('/api/ideaPositions/save', {
          userId,
          ideaId: idea.ideaId,
          discussionId,
          width: state.width,
          height: state.height,
          x: state.x,
          y: state.y
        });
      } catch (err) { setError(err.response.data) }
    }
    didMountRef.current = true;
    // eslint-disable-next-line
  }, [state.x, state.y, state.width, state.height])

  return (
    <Draggable
      defaultPosition={{x: idea.x, y: idea.y}}
      onStart={onStart}
      onStop={onDrop}
      handle={'strong'}
      nodeRef={ideaRef}
    >
      <div
        className={`${classes.ideaColor} idea-target ${state.activeDrags ? classes.noPointerEvents : ""}`}
        ref={ideaRef}
        style={{ width: idea.width, height: idea.height }}
        data-id={idea.ideaId}
      >
        <strong className={classes.dragHandle}></strong>
        <Typography className={classes.ideaText}>idea: {idea.content}</Typography>
      </div>
    </Draggable>
  )
}