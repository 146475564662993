import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_SOCKETIO_CLIENT, {
  closeOnBeforeunload: false,
  autoConnect: false
});

socket.on("connect", () => {
  console.log("socket connected");
});

// for debugging, captures all events
// socket.onAny((event, ...args) => {
//   console.log(event, args);
// });

// error handling event
socket.on("connect_error", (err) => {
  if (err.message === "invalid username") {
    // this.usernameAlreadySelected = false;
  }
});

socket.on("disconnect", () => {
  console.log("socket disconnected");
});

export default socket;