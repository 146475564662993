import React from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import { DeleteForever, ArrowUpward, ArrowDownward } from "@material-ui/icons";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from "@mui/material";

// imports all componnt editor forms
import * as ComponentForms from "./componentforms";

//For page preview
import ComponentCombiner from "../../utils/ComponentCombiner";

//To get author id for key creation
import store from "../../store";

const styles = (theme) => ({
  buttons: {
    marginRight: 15,
    marginTop: 20,
  },
  compForm: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    width: 800,
  },
  selectStyle: {
    margin: theme.spacing(1),
    marginRight: 15,
    marginTop: 20,
    minWidth: 150,
  },
});

class Editor extends React.Component {
  constructor(props) {
    super(props);
    const { answerObjects, pageObjects, editorInitialView } = this.props;
    this.state = {
      pageObjects,
      answerObjects,
      previewView: editorInitialView ? false : true,
      publishConfirm: false,
    };
  }

  preview = () => {
    const { answerObjects, pageObjects } = this.state;
    return answerObjects && pageObjects ? (
      <ComponentCombiner
        updateAnswer={() => { }} // dummy function, does nothing
        pageAnswers={answerObjects}
        collection={pageObjects}
        editor={true}
      />
    ) : null;
  };

  handleComponentEdit = (propName, value, fieldKey) => {
    const pageObjects = [...this.state.pageObjects];
    const index = pageObjects.findIndex(
      (comp) => comp.props.fieldKey === fieldKey
    );
    pageObjects[index].props = {
      ...pageObjects[index].props,
      [propName]: value,
    };
    this.setState({
      pageObjects,
    });
    this.props.setChanged();
  };

  // handles forms sending updates to corresponding answer objects
  handleUpdateAnswer = (fieldKey, answer) => {
    const answerObjects = { ...this.state.answerObjects };
    answerObjects[fieldKey] = answer;
    this.setState({ answerObjects });
  };

  setPublishConfirm = (value) => {
    this.setState({
      publishConfirm: value
    })
  }

  swapComponents = (i, j) => {
    const pageObjects = [...this.state.pageObjects];
    pageObjects[i] = this.state.pageObjects[j];
    pageObjects[j] = this.state.pageObjects[i];
    this.setState({
      pageObjects,
    });
    this.props.setChanged();
  };

  addComponent = (comp) => {
    const pageObjects = [...this.state.pageObjects];
    const answerObjects = { ...this.state.answerObjects };
    const newComp = ComponentForms[comp].createNew(
      store.getState().auth.user.authorId
    );
    pageObjects.push(newComp.comp);
    answerObjects[newComp.comp.props.fieldKey] = newComp.answer;
    this.setState({
      pageObjects,
      answerObjects,
    });
    this.props.setChanged();
  };

  renderEditor = () => {
    const { answerObjects, pageObjects } = this.state;
    const { classes } = this.props;
    return (
      <div>
        {pageObjects.map((comp, index) => {
          // Get form for component
          const Form =
            ComponentForms[comp.type] && ComponentForms[comp.type].Form;
          const answer = answerObjects[comp.props.fieldKey];
          if (!Form) return console.log(`No edit form for ${comp.type} found.`);

          const buttons = (
            <div style={{ float: "right" }}>
              <IconButton
                disabled={index === 0}
                onClick={() => this.swapComponents(index, index - 1)}
              >
                <ArrowUpward />
              </IconButton>
              <IconButton
                disabled={index === pageObjects.length - 1}
                onClick={() => this.swapComponents(index, index + 1)}
              >
                <ArrowDownward />
              </IconButton>
              <IconButton
                onClick={() => {
                  const newPageObjects = [...pageObjects];
                  const removedObject = newPageObjects.splice(index, 1);
                  const answerId = removedObject[0].props.fieldKey;
                  const newAnswerObject = answerObjects;
                  delete newAnswerObject[answerId];
                  this.setState({
                    pageObjects: newPageObjects,
                    answerObjects: newAnswerObject,
                  });
                }}
              >
                <DeleteForever />
              </IconButton>
            </div>
          );

          // Render component edit form with callbacks for updating data
          return (
            <Paper
              key={comp.props.fieldKey}
              elevation={3}
              className={classes.compForm}
            >
              <Form
                updateComponent={(propName, value) =>
                  this.handleComponentEdit(propName, value, comp.props.fieldKey)
                }
                updateAnswer={(newAnswer, fieldKey) =>
                  this.handleUpdateAnswer(
                    fieldKey || comp.props.fieldKey,
                    newAnswer
                  )
                }
                buttons={buttons}
                data={comp.props}
                answer={answer}
                answerObjects={answerObjects} // used by content slider form
              />
            </Paper>
          );
        })}
        <Select
          className={classes.selectStyle}
          value="+ Add Component"
          onChange={(event) => this.addComponent(event.target.value)}
        >
          <MenuItem disabled value="+ Add Component">
            + Add Component
          </MenuItem>
          {Object.keys(ComponentForms).map((comp) => (
            <MenuItem key={comp} value={comp}>
              {ComponentForms[comp].name}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  };

  render() {
    const { answerObjects, pageObjects, previewView, publishConfirm } = this.state;
    const { classes, saveNewPage, updatePage, publishPage } = this.props;

    return (
      <div>
        <div style={{marginTop: -625, marginLeft: 425}}>
          <Button
            className={classes.buttons}
            variant="contained"
            color="primary"
            onClick={() => this.setState({ previewView: !previewView })}
          >
            {previewView ? "Edit Page" : "Show Preview"}
          </Button>
          {saveNewPage ? (
            <Button
              className={classes.buttons}
              variant="contained"
              color="secondary"
              onClick={() => saveNewPage(pageObjects, answerObjects)}
            >
              Save Page
            </Button>
          ) : null}
          {updatePage ? (
            <Button
              className={classes.buttons}
              variant="contained"
              color="secondary"
              onClick={() => updatePage(pageObjects, answerObjects)}
            >
              Save Page
            </Button>
          ) : null}
          <Button
            className={classes.buttons}
            variant="contained"
            style={{ color: 'white', backgroundColor: 'green' }}
            onClick={() => this.setPublishConfirm(true)}
          >
            Publish Page
          </Button>
        </div>
        <br/>
        <div>
          {previewView ? this.preview() : this.renderEditor()}
        </div>
            <Dialog
              open={publishConfirm}
              onClose={() => this.setPublishConfirm(false)}
              aria-labelledby="alert-publish-confirm"
              aria-describedby="alert-publish-confirm-dialog"
            >
               <DialogTitle id="alert-public-confirm">
                    Publish this page
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-publish-confirm-dialog">
                        <br/>
                        <Typography>Are you sure you want to publish this page? You should only publish a page once you feel you have finalized the content on the page. Making changes to published pages increases the risk of having inaccurate data for the users.</Typography>
                        <br/>
                        <Typography>If you are sure, click Publish.</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => this.setPublishConfirm(false)}>Cancel</Button>
                    <Button variant="contained" onClick={() => {publishPage(pageObjects, answerObjects); this.setPublishConfirm(false);}}>Publish</Button>
                </DialogActions>
            </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(Editor);
