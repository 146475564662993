export const pushMapping = {
  '/Sunflower': {
    x: 50,
    y: 45,
    groupBorderColor: '#EB8D48'
  },
  '/Squash': {
    x: 472,
    y: 45,
    groupBorderColor: '#FAB8B8'
  },
  '/Soybean': {
    x: 860,
    y: 45,
    groupBorderColor: '#E2F4C6'
  },
  '/Radish': {
    x: 50,
    y: 210,
    groupBorderColor: '#E2CBFF'
  },
  '/Potato': {
    x: 472,
    y: 210,
    groupBorderColor: '#B88787'
  },
  '/Lettuce': {
    x: 860,
    y: 210,
    groupBorderColor: '#E4E06C'
  },
  '/Kale': {
    x: 50,
    y: 385,
    groupBorderColor: '#A0B5FF'
  },
  '/Green Bean': {
    x: 472,
    y: 385,
    groupBorderColor: '#AEF4D3'
  },
  '/Corn': {
    x: 860,
    y: 385,
    groupBorderColor: '#FAB8B8'
  },
};