import { useState } from 'react';
import axios from 'axios';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Typography,
  Box,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import AddIcon from '@material-ui/icons/Add';
import { Button } from './common/Button';
import socket from '../../utils/socket';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(5)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  menuItem: {
    textTransform: 'capitalize'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedTags, theme) {
  return {
    fontWeight:
    selectedTags.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function StudentResponseForm({
  userId,
  room,
  noteText,
  originId,
  discussionId,
  handleClose,
  handleNoteTextChange,
  tags,
  newTag,
  setNewTag,
  handleNewTag
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedTags, setSelectedTags] = useState([]);
  const [error, setError] = useState(null);

  const handleSubmit = e => {
    e.preventDefault();

    const originType =  originId ? 'notebook' : 'new';

    (async() => {
      try {
        const note = await axios.post('/api/notes/save', {
          content: noteText,
          tags: selectedTags,
          userId,
          discussionId,
          originId,
          originType: originType,
          originRoom: room
        });
        const notePosition = await axios.post('/api/notePositions/save', {
          userId,
          noteId: note.data._id,
          discussionId,
          content: noteText,
          tags: selectedTags,
          originId,
          originType: originType,
          originRoom: room
        });
        socket.emit(`${room}/new`, notePosition.data);
      } catch (err) {
        setError(err.response.data)
      } finally {
        setNewTag('');
        setError('');
        setSelectedTags([]);
        handleClose();
      }
    })();
  }

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom="25px"
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">New Note</Typography>
      </Box>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              id="note-text"
              variant="outlined"
              defaultValue={noteText}
              onChange={handleNoteTextChange}
              multiline
              required
              autoFocus
              rows={6}
              placeholder='Type your new idea here...'
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="tag-select-label">Select a Tag</InputLabel>
            <Select
              labelId="tag-select-label"
              id="tag-select"
              multiple
              value={selectedTags}
              onChange={(event) => setSelectedTags(event.target.value)}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {tags && tags.map((tag) => (
                <MenuItem key={tag} value={tag} style={getStyles(tag, selectedTags, theme)}>
                  {tag}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="standard"
            variant="standard"
            value={newTag}
            onChange={(event) => setNewTag(event.target.value)}
            placeholder="Add a Tag"
            sx={{marginTop: '16px'}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="add new tag"
                    onClick={handleNewTag}
                    edge="end"
                  >
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={3}>
          <Button
            primary
            onClick={handleClose}
            style={{position: 'relative'}}
          ><div className='text-wrapper'>Cancel</div></Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            type="submit"
            primary
            style={{position: 'relative'}}
          ><div className='text-wrapper'>Publish</div></Button>
        </Grid>
      </Grid>
    </form>
  )
}