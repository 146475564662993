import React, { useEffect } from "react";
import { ToggleButtonGroup, ToggleButton, TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } from "@mui/material";
import Iframe from "react-iframe";

export default function SimulationContent(props) {

    const [simURL, setURL] = React.useState("");
    const [simText, setSimText] = React.useState("");
    const [selectedSim, setSelected] = React.useState("");
    const [urlInput, setURLInput] = React.useState("");
    const fieldKeyValue = props.pageObjs[0].props.fieldKey;
    const [publishDialog, setPublishDialog] = React.useState(false);

    useEffect(() => {
        let simProps = props.pageObjs[0].props;
        if (simProps.simText == '' && simProps.simUrl == '') {
            //empty page
        } else if (simProps.simText != '') {
            setSimText(simProps.simText);
            setSelected("text");
        } else {
            setURL(simProps.simUrl);
            setURLInput(simProps.simUrl);
            setSelected("link");
        }
    }, []);

    const handleChange = (event) => {
        setSelected(event.target.value);
    }

    const displayURL = () => {
        //if url isn't blank try to render in iframe
        if (simURL != undefined && simURL.length > 1) {
            return (
                <div>
                    <Iframe
                        url={simURL}
                        width="720px"
                        height="500px"
                        id="simulation"
                        display="initial"
                        position="relative"
                    />
                    <br/>
                    <br/>
                    <TextField sx={{ width: 750 }} id="outlined-basic" label="Simulation URL" variant="outlined" value={urlInput} onChange={(event) => setURLInput(event.target.value)} />
                    <Button sx={{ height: 55, width: 125, marginLeft: 2 }} variant="contained" color="primary" onClick={() => setURL(urlInput)}>Preview</Button>
                </div>
            )
        } else {
            return(
                <div>
                    <br/>
                    <TextField sx={{ width: 750 }} id="outlined-basic" label="Simulation URL" variant="outlined" value={urlInput} onChange={(event) => setURLInput(event.target.value)} />
                    <Button sx={{ height: 55, width: 125, marginLeft: 2 }} variant="contained" color="primary" onClick={() => setURL(urlInput)}>Preview</Button>
                </div>
            )
        }
    }

    const displayText = () => {
        //Replace textfield with rich text
        return(
            <div>
                <TextField
                    id="standard-textarea"
                    label="Lab Information"
                    placeholder=""
                    multiline
                    rows={8}
                    style={{ width: 800 }}
                    value={simText}
                    onChange={(event) => setSimText(event.target.value)}
                />
            </div>
        )
    }

    const displayContent = () => {
        if (selectedSim === "text") {
            return displayText()
        } else if (selectedSim === "link") {
            return displayURL();
        }
    }

    const savePage = () => {
        let newObj = {fieldKey: fieldKeyValue, simUrl: "", simText: ""};
        if (selectedSim == "link") {
            newObj.simUrl = simURL;
        } else {
            newObj.simText = simText;
        }
        props.updatePage([{type: "PageRollercoasterSim", props: newObj}], {});
    }

    const publishPage = () => {
        let newObj = {fieldKey: fieldKeyValue, simUrl: "", simText: ""};
        if (selectedSim == "link") {
            newObj.simUrl = simURL;
        } else {
            newObj.simText = simText;
        }
        props.publishPage([{type: "PageRollercoasterSim", props: newObj}], {});
    }

    return(
        <div style={{ marginLeft: 25, marginTop: -570 }}>
            <br/>
            <ToggleButtonGroup
                color="primary"
                value={selectedSim}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
            >
                <ToggleButton sx={{width: 250 }} value="link">Link</ToggleButton>
                <ToggleButton sx={{width: 250 }} value="text">Text</ToggleButton>
            </ToggleButtonGroup>
            <Button sx={{marginTop: -17, marginLeft: -15 }} variant="contained" color="secondary" onClick={() => savePage()}>Save Page</Button>
            <Button sx={{marginLeft: 3, marginTop: -17 }} variant="contained" style={{ color: 'white', backgroundColor: 'green' }} onClick={() => setPublishDialog(true)}>Publish Page</Button>
            <br/>
            <br/>
            <br/>
            {displayContent()}
            <Dialog
              open={publishDialog}
              onClose={() => setPublishDialog(false)}
              aria-labelledby="alert-publish-confirm"
              aria-describedby="alert-publish-confirm-dialog"
            >
               <DialogTitle id="alert-public-confirm">
                    Publish this page
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-publish-confirm-dialog">
                        <br/>
                        <Typography>Are you sure you want to publish this page? You should only publish a page once you feel you have finalized the content on the page. Making changes to published pages increases the risk of having inaccurate data for the users.</Typography>
                        <br/>
                        <Typography>If you are sure, click Publish.</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setPublishDialog(false)}>Cancel</Button>
                    <Button variant="contained" onClick={() => {publishPage(); setPublishDialog(false);}}>Publish</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}