import React from "react";
import axios from "axios";
import { withRouter, Link, Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
} from "@material-ui/core";

//Left nav bar icons
import IconButton from "@material-ui/core/IconButton";
import HomeIcon from "@material-ui/icons/Home";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MapIcon from "@mui/icons-material/Map";
import Button from "@mui/material/Button";

import StepperNavigation from "./StepperNavigation";
import TabNavigation from "./TabNavigation";
import Intro from "./IntroPage";

//Note Functionality
import NoteManager from "./elements/Notes/NoteManager";

import { logoutUser } from "../../actions/authActions";

const SLItem = withStyles({
  root: {
    backgroundColor: "transparent",
    //paddingLeft: "50px",
    // paddingRight: "0px",
    "&$selected": {
      backgroundColor: "rgba(21, 118, 209, 0.2)",
      borderTopLeftRadius: "1000px",
      borderBottomLeftRadius: "1000px",
      borderTopRightRadius: "1000px",
      borderBottomRightRadius: "1000px",
      "& .MuiListItemIcon-root": {
        //color: "white"
      },
    },
    "&$selected:hover": {
      //backgroundColor: 'rgba(255, 255, 255,0.3) ',
      borderTopLeftRadius: "1000px",
      borderBottomLeftRadius: "1000px",
      borderTopRightRadius: "1000px",
      borderBottomRightRadius: "1000px",
      // backgroundColor: "purple",
      // color: "white",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
    "&:hover": {
      //backgroundColor: 'rgba(255, 255, 255,0.1)',
      borderTopLeftRadius: "1000px",
      borderBottomLeftRadius: "1000px",
      borderTopRightRadius: "1000px",
      borderBottomRightRadius: "1000px",
      "& .MuiListItemIcon-root": {
        //color: "white"
      },
    },
  },
  selected: {},
  /*
    root: {
      backgroundColor: 'transparent',
      paddingLeft: "50px",

      '&:hover': {
          backgroundColor: 'rgba(255, 255, 255,0.1)',
          borderTopLeftRadius: '1000px',
          borderBottomLeftRadius: '1000px',
          // paddingLeft: '10px'
      },

      "&.Mui-selected": {
        backgroundColor: 'rgba(255, 255, 255,0.3) ',
        borderTopLeftRadius: '1000px',
        borderBottomLeftRadius: '1000px',
        // marginLeft: '20px',
        // paddingLeft: '-20px'
        // color: innerTheme.secondary
      },

      // "&.Mui-selected &:hover": {
      //   backgroundColor: 'rgba(0,0,0,0.3) ',
      //   borderTopLeftRadius: '20px',
      //   borderBottomLeftRadius: '20px',
      // }

    },*/
})(ListItem);

const styles = (theme) => ({
  root: {
    display: "flex",
    //backgroundColor: '#c7c7c7',
    overflowY: "hidden",
    //height: '200hv'
  },
  appBar: {
    //zIndex: theme.zIndex.drawer + 4,
    //paddingLeft: '20px',
    //backgroundColor: "#FFFFFF"
  },
  drawer: {
    // width: drawerWidth,
    flexGrow: 1,
    // flexShrink: 0,
    // flex:1,
    //justifyContent: "flex-end",
    //display: "flex",
    width: 90,
    //background: "transparent",
    //paddingRight: "0px"
    // marginLeft: "24px",
    // paddingLeft: '20px',
  },
  drawerPaper: {
    // maxWidth: drawerWidth,
    // paddingLeft: '40px',
    // paddingRight: '20px',
    paddingTop: "70px",
    //background: "transparent",
    //color: "white",
    width: 90,
    //marginLeft: 24,
    // paddingRight: "-24px"
    // paddingRight: "0px"
    // justifyContent: "flex-end"
  },
  drawerContainer: {
    overflow: "auto",
    //background: "transparent",
    // justifyContent: "flex-end",
    // paddingRight: "60px"
  },
  nested: {
    paddingLeft: theme.spacing(9),
  },

  content: {
    //flexGrow: 1,
    //zIndex: theme.zIndex.drawer + 2,
    // flex:11,
    width: "90%",
    //backgroundColor: '#c7c7c7',
    //minHeight: "1000px",
    // padding: theme.spacing(3),
    paddingTop: "50px",
    paddingRight: "30px",
    paddingLeft: "0px",
    borderTopLeftRadius: "30px",
  },
  steps: {
    //background: "transparent",
    width: "90%",
    marginLeft: "10%",
  },
  stepButton: {
    //background: "transparent",
  },
  step_label_root: {
    fontSize: "16px",
    //color: "rgba(255, 255, 255, 0.6)",
    color: "#bec6eb",
    "&.MuiStepLabel-active": {
      color: "white",
      fontWeight: "bold",
    },
    "&.Mui-disabled .MuiStepLabel-label": {
      color: "#7e89bd",
    },
  },
  default_tab: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  iconText: {
    paddingTop: 50,
    position: "absolute",
  },
});

class Notebook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navigation: props.navigation || null,
      tableHeaders: null,
      showNotes: false,
      noteClear: false,
      timeIn: 0,
      timeURL: "",
      rerender: true,
    };

    // Bind to allow state reference when used as callback
    this.updateFurthestLab = this.updateFurthestLab.bind(this);
    this.unlockLab = this.unlockLab.bind(this);
    this.closeNotes = this.closeNotes.bind(this);
    this.clearNotes = this.clearNotes.bind(this);
    this.studentRequest = this.studentRequest.bind(this);
    this.exit = this.exit.bind(this);
    this.setRender = this.setRender.bind(this);
  }

  setRender() {
    if (this.state.rerender) {
      this.setState({ rerender: false });
    } else {
      this.setState({ rerender: true });
    }
  }

  // Wrapper function for student specific requests to be supressed when in student preview mode
  studentRequest(endpoint, body) {
    if (this.props.previewMode) {
      console.log(
        `Supressing request to endpoint ${endpoint} while in preview mode.`
      );
      return new Promise((resolve) => {
        if (endpoint === "/api/answers/getnotes") {
          // If fetching notes, return empty notes data
          resolve({ data: { notes: [], notes_taken: 0 } });
        } else {
          resolve();
        }
      });
    }
    return axios.post(endpoint, body);
  }

  componentDidMount() {
    const { user } = this.props.auth;
    // Return early if student preview mode, since navigation is passed as prop
    if (this.props.previewMode) return;
    // Get student navigation
    if (user.access === 1 || user.access === -1) {
      axios
        .post("/api/navigation/getnavigation", { studentId: user.id })
        .then((res) => {
          this.setState({
            navigation: res.data[0],
          });
        })
        .catch((err) => console.error(err));

      // Get teacher navigation
    } else if (user.access === 2) {
      axios
        .post("/api/navigation/getteachernavigation", { studentId: user.id })
        .then((res) => {
          this.setState({
            navigation: res.data[0],
          });
        })
        .catch((err) => console.error(err));
    }
  }

  logTime() {
    let timeSpent = Date.now() - this.timeIn;
    if (timeSpent > 3000) {
      let seconds = Math.round(timeSpent / 1000);
      this.studentRequest("/api/answers/savetime", {
        timeSpent: seconds,
        id: this.props.auth.user.id,
        urlString: this.timeURL,
      }).catch((error) => console.error(error));
    }
    this.timeIn = Date.now();
    let urlValues = this.props.location.pathname.split("/");
    urlValues.splice(0, 2);
    let urlString = urlValues.join("/");
    this.timeURL = urlString;
  }

  componentDidUpdate(prevProps) {
    this.logTime();

    // If lab changed, handle update
    const newLab = this.props.match.params.activeLab;
    if (newLab !== prevProps.match.params.activeLab && newLab >= 0)
      this.handleLabUpdate(newLab);
  }

  // Clears visible notes and fetches new data table headers if they exist
  handleLabUpdate(newLab) {
    const { navigation } = this.state;
    this.clearNotes(true);
    if (
      navigation &&
      navigation.Labs[newLab].pages.data &&
      navigation.Labs[newLab].tabTitles.data === "Data"
    ) {
      axios
        .post("/api/navigation/getdatatableheaders", {
          page: navigation.Labs[newLab].pages.data,
        })
        .then((response) =>
          this.setState({
            tableHeaders: response.data,
          })
        );
    } else {
      this.setState({ tableHeaders: null });
    }
  }

  // Updates the furthest lab to a new step
  updateFurthestLab(step) {
    const { navigation } = this.state;
    this.setState({ navigation: { ...navigation, Furthest_Lab: step } });
    //write to database
    this.studentRequest("/api/navigation/updatelab", {
      navigationId: navigation._id,
      updatedStep: step,
    }).catch((error) => console.error(error));
  }

  // Sets a specific lab as completed
  // NOT USED ANYWHERE YET
  // setCompleted(step) {
  //   const { navigation } = this.state;
  //   const newLabs = [...navigation.Labs];
  //   newLabs[step] = { ...navigation.Labs[step], completed: true };
  //   this.setState({ navigation: { ...navigation, Labs: newLabs } });

  //   //write to database (use studentRequest wrapper)
  //   // TODO need to make a route to set completed for a given lab
  // }

  unlockLab(lab, callback) {
    const newNav = {
      ...this.state.navigation,
      Labs: [...this.state.navigation.Labs],
    };
    newNav.Labs[lab] = {
      ...newNav.Labs[lab],
      unlocked: true,
    };

    this.studentRequest("/api/navigation/unlocklab", {
      nav: newNav,
    }).then(() => this.setState({ navigation: newNav }, callback));
  }

  closeNotes() {
    this.setState({ showNotes: false });
  }

  clearNotes(clearVal) {
    this.setState({ noteClear: clearVal });
  }

  // Logs student out or exits student preview view
  exit() {
    const { previewMode, baseUrl, logoutUser, history } = this.props;
    if (previewMode) {
      history.push(baseUrl.substr(0, baseUrl.indexOf("/notebook")));
    } else {
      logoutUser();
    }
  }

  render() {
    const { classes, auth, baseUrl } = this.props;
    const { navigation, tableHeaders, showNotes, noteClear } = this.state;

    const { activeLab, activeTab, activeStep } = this.props.match.params;

    const drawerData = [
      {
        title: "Home",
        url: activeLab ? `${baseUrl}/${activeLab}/home` : baseUrl,
        selected: !activeLab || activeTab === "home",
        icon: <HomeIcon fontSize="large" />,
      },
      /*
          {
            Title: "Agent",
            url: "design",
            icon: <SupportAgentIcon fontSize="large" />,
          },
          */
      {
        title: "Notes",
        onClick: () => this.setState({ showNotes: true }),
        disabled: !activeLab,
        icon: <LibraryBooksIcon fontSize="large" />,
      },
      {
        title: "Sim",
        url: `${baseUrl}/${activeLab}/simulation`,
        disabled: !activeLab,
        selected: activeTab === "simulation",
        icon: <SlideshowIcon fontSize="large" />,
      },
      {
        title: "Vidyamap",
        url: "/vidyamap",
        target: "_blank",
        icon: <MapIcon fontSize="large" />,
      },
      {
        title: "Exit",
        onClick: this.exit,
        icon: <ExitToAppIcon fontSize="large" />,
      },
    ];

    // If navigation not loaded yet, return empty component
    if (!navigation) {
      return <div></div>;
    }

    // This catches all invalid activeLab values including all non-numbers
    if (!(activeLab >= 0)) {
      // Redirects to furthest lab if labs have been started, else navigates to home if not already there
      if (navigation.Furthest_Lab >= 0) {
        return <Redirect to={`${baseUrl}/${navigation.Furthest_Lab}`} />;
      } else if (activeLab) {
        // If active lab invalid value, redirect to base notebook URL
        return <Redirect to={baseUrl} />;
      }
    }

    // If user is past their furthest lab, either update db if unlocked or redirect
    if (activeLab > navigation.Furthest_Lab) {
      if (navigation.Labs[activeLab]?.unlocked) {
        this.updateFurthestLab(parseInt(activeLab));
      } else {
        return <Redirect to={`${baseUrl}/${navigation.Furthest_Lab}`} />;
      }
    }

    // If invalid page type redirect to overview page
    if (
      activeTab &&
      !["experiment", "data", "analysis", "simulation", "home"].includes(
        activeTab
      )
    ) {
      return <Redirect to={`${baseUrl}/${activeLab}`} />;
    }

    let activePageId;
    if (!activeLab || activeTab === "home") {
      activePageId = navigation.Side_Navigation.home;
    } else if (activeTab === "simulation") {
      //activePageId = navigation.Side_Navigation.simulation;
      activePageId = navigation.Labs[activeLab].simulation;
    } else {
      activePageId = navigation.Labs[activeLab].pages[activeTab || "overview"];
    }

    // Conditionally set the page ID to save answers to.
    // If sim page active and user has a valid active lab, save data to data table.
    const answersPageId =
      activeLab && activeTab === "simulation" && navigation.Labs[activeLab].type != "essay"
        ? navigation.Labs[activeLab].pages.data
        : activePageId;

    return (
      <div className={classes.root}>
        <CssBaseline />

        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
          {this.state.rerender ?
            <StepperNavigation
              classes={classes}
              activeLab={activeLab}
              navigation={navigation}
              baseUrl={baseUrl}
              rerender={this.state.rerender}
            />
          :
            <StepperNavigation
              classes={classes}
              activeLab={activeLab}
              navigation={navigation}
              baseUrl={baseUrl}
              rerender={this.state.rerender}
            />
          }
          </Toolbar>
        </AppBar>
        <NoteManager
          show={showNotes}
          close={this.closeNotes}
          clearCheck={noteClear}
          clear={this.clearNotes}
          navigation={navigation}
          activeLab={activeLab}
          activeTab={activeTab}
          studentRequest={this.studentRequest}
          baseUrl={baseUrl}
        />
        <Drawer
          className={classes.drawer}
          anchor="left"
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerContainer}>
            <List component="li">
              {drawerData.map((item, index) => (
                <SLItem
                  button
                  style={{ paddingBottom: 20 }}
                  key={"listitem" + index}
                  component={item.url ? Link : null}
                  to={item.url}
                  onClick={item.onClick}
                  disabled={item.disabled}
                  target={item.target}
                  selected={item.selected}
                >
                  <IconButton>
                    {item.icon}
                    <Typography className={classes.iconText}>
                      {item.title}
                    </Typography>
                  </IconButton>
                </SLItem>
              ))}
            </List>
          </div>
        </Drawer>

        <main className={classes.content} style={{ marginTop: 20 }}>
          {
            // Conditionally render tabs depending on current page
            navigation.Labs[activeLab] &&
            navigation.Labs[activeLab].type !== "essay" &&
            !["simulation", "home"].includes(activeTab) ? (
              <TabNavigation
                classes={classes}
                activeLab={activeLab}
                activeTab={activeTab}
                baseUrl={baseUrl}
                navigation={navigation}
              />
            ) : null
          }
          <Intro
            navigation={navigation}
            userid={auth.user.id}
            pageId={activePageId}
            answersPageId={answersPageId}
            activeLab={activeLab}
            activeTab={activeTab}
            activeStep={activeStep}
            tableHeaders={tableHeaders}
            studentRequest={this.studentRequest}
            unlockLab={this.unlockLab}
            baseUrl={baseUrl}
            setRender={this.setRender}
          />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default compose(
  connect(mapStateToProps, { logoutUser }),
  withStyles(styles),
  withRouter
)(Notebook);