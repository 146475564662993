import { useState, useContext } from 'react';
import axios from 'axios';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Box,
  Typography,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import AddIcon from '@material-ui/icons/Add';
import { Button } from './common/Button';
import socket from '../../utils/socket';
import { NoteContext } from '../../reducers/noteContext';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(5)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  menuItem: {
    textTransform: 'capitalize'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedTags, theme) {
  return {
    fontWeight:
    selectedTags.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function StudentCombineForm({
  userId,
  room,
  discussionId,
  ingredientIds,
  handleClose,
  combinedContent,
  tags,
  newTag,
  setNewTag,
  handleNewTag
}) {
  const classes = useStyles();
  const theme = useTheme();

  const [text, setText] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [, dispatch] = useContext(NoteContext);

  const handleSubmit = async(e) => {
    e.preventDefault();

    try {
      const note = await axios.post('/api/notes/save', {
        content: text,
        tags: selectedTags,
        combinedContent,
        userId,
        ingredientIds,
        discussionId,
        originType: 'combined',
        originRoom: room
      });
      const notePosition = await axios.post('/api/notePositions/save', {
        userId,
        noteId: note.data._id,
        discussionId,
        content: text,
        tags: selectedTags,
        ingredientIds,
        combinedContent,
        originType: 'combined',
        originRoom: room
      });
      socket.emit(`${room}/new`, notePosition.data);
      if (ingredientIds && ingredientIds.length > 0) {
        for (let ingredientId of ingredientIds) {
          await axios.post('/api/notePositions/save', {
            userId: userId,
            noteId: ingredientId,
            discussionId: discussionId,
            combined: true
          });
          socket.emit(`${room}/combined`, ingredientId);
          dispatch({
            type: 'UPDATE_NOTE_COMBINED',
            id: ingredientId
          });
        }
      }
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: err
      });
    }
    setText('');
    setSelectedTags([]);
    handleClose();
  }

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginBottom="25px"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">Combine Notes</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          marginBottom="25px"
          sx={{borderRadius: '25px', border: '3px solid #B4B4B4', background: '#FFF'}}
        >
          <Typography id="modal-modal-subtitle" variant="h6" component="h2">Original Notes</Typography>
          <Typography>{combinedContent}</Typography>
        </Box>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                id="standard"
                variant="outlined"
                value={text}
                onChange={(event) => setText(event.target.value)}
                multiline
                required
                autoFocus
                rows={6}
                placeholder='Type your new idea here...'
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="tag-select-label">Select a Tag</InputLabel>
              <Select
                labelId="tag-select-label"
                id="tag-select"
                multiple
                value={selectedTags}
                onChange={(event) => setSelectedTags(event.target.value)}
                input={<Input />}
                MenuProps={MenuProps}
              >
                {tags && tags.map((tag) => (
                  <MenuItem key={tag} value={tag} style={getStyles(tag, selectedTags, theme)}>
                    {tag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard"
              variant="standard"
              value={newTag}
              onChange={(event) => setNewTag(event.target.value)}
              placeholder="Add a Tag"
              sx={{marginTop: '16px'}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="add new tag"
                      onClick={handleNewTag}
                      edge="end"
                    >
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={3}>
            <Button
              primary
              onClick={handleClose}
              style={{position: 'relative'}}
            ><div className='text-wrapper'>Cancel</div></Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              type="submit"
              primary
              style={{position: 'relative'}}
            ><div className='text-wrapper'>Publish</div></Button>
          </Grid>
        </Grid>
    </form>
  )
}