import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: '#fff',
      '&:visited': {
        textDecoration: 'none',
        color: '#fff'
      }
  }
}))

export function IndividualKnowledgeWall() {
  const classes = useStyles();
  return (
    <Grid container justify="center" alignItems="center" direction="column">
        <Grid item>
          <Button variant="contained" color="primary" sx={{marginTop: 5}}>
            <Link className={classes.link} to='/fulldiscussion/note' target="_blank" rel="noopener noreferrer">Knowledge Wall</Link>
          </Button>
        </Grid>
    </Grid>
  )
}

export default IndividualKnowledgeWall;