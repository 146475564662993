import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import Grid from "@mui/material/Grid";

import store from "../../../../store";

export function ShortAnswersToNote(props) {

    let user = store.getState().auth.user.id;

    //forgive me for this code...
    const [answer1, setAnswer1] = React.useState(props.answers.answer[0]);
    const [answer2, setAnswer2] = React.useState(props.answers.answer[1]);
    const [answer3, setAnswer3] = React.useState(props.answers.answer[2]);
    const [answer4, setAnswer4] = React.useState(props.answers.answer[3]);
    const [answer5, setAnswer5] = React.useState(props.answers.answer[4]);
    const [answer6, setAnswer6] = React.useState(props.answers.answer[5]);

    const handleAnswer1 = (event) => {
        setAnswer1(event.target.value);
        props.returnData({ answer: [event.target.value, answer2, answer3, answer4, answer5, answer6] }, props.fieldKey);
    }

    const handleAnswer2 = (event) => {
        setAnswer2(event.target.value);
        props.returnData({ answer: [answer1, event.target.value, answer3, answer4, answer5, answer6] }, props.fieldKey);
    }

    const handleAnswer3 = (event) => {
        setAnswer3(event.target.value);
        props.returnData({ answer: [answer1, answer2, event.target.value, answer4, answer5, answer6] }, props.fieldKey);
    }

    const handleAnswer4 = (event) => {
        setAnswer4(event.target.value);
        props.returnData({ answer: [answer1, answer2, answer3, event.target.value, answer5, answer6] }, props.fieldKey);
    }

    const handleAnswer5 = (event) => {
        setAnswer5(event.target.value);
        props.returnData({ answer: [answer1, answer2, answer3, answer4, event.target.value, answer6] }, props.fieldKey);
    }

    const handleAnswer6 = (event) => {
        setAnswer6(event.target.value);
        props.returnData({ answer: [answer1, answer2, answer3, answer4, answer5, event.target.value] }, props.fieldKey);
    }

    function sendToNotes() {
        var title = "Plant factors";
        var body = answer1 + "\n" + answer2 + "\n" + answer3 + "\n" + answer4 + "\n" + answer5 + "\n" + answer6;
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = mm + "/" + dd + "/" + yyyy;
        var date = today;
        //hardcoded since this only appears in one lab
        //for knowledge wall, the lab should be Knowledge Wall
        //CASE SENSITIVE!!!
        var labName = "Investigating Plants";
        axios.post("/api/answers/pushnotes", {
            lab: labName,
            userid: user,
            title: title,
            body: body,
            date: date,
        }).then(result => {
            //console.log(result);
        });
    }

    return (
        <div>
            <Grid container alignItems="center" justify="center" direction="column">
            <Typography>{props.questions[0]}</Typography>
            <br/>
            <TextField
                multiline
                value={answer1}
                onChange={handleAnswer1}
                rows={4}
                style={{ width: 600 }}
            />
            <br/>
            <br/>
            <Typography>{props.questions[1]}</Typography>
            <br/>
            <TextField
                multiline
                value={answer2}
                onChange={handleAnswer2}
                rows={4}
                style={{ width: 600 }}
            />
            <br/>
            <br/>
            <Typography>{props.questions[2]}</Typography>
            <br/>
            <TextField
                multiline
                value={answer3}
                onChange={handleAnswer3}
                rows={4}
                style={{ width: 600 }}
            />
            <br/>
            <br/>
            <Typography>{props.questions[3]}</Typography>
            <br/>
            <TextField
                multiline
                value={answer4}
                onChange={handleAnswer4}
                rows={4}
                style={{ width: 600 }}
            />
            <br/>
            <br/>
            <Typography>{props.questions[4]}</Typography>
            <br/>
            <TextField
                multiline
                value={answer5}
                onChange={handleAnswer5}
                rows={4}
                style={{ width: 600 }}
            />
            <br/>
            <br/>
            <Typography>{props.questions[5]}</Typography>
            <br/>
            <TextField
                multiline
                value={answer6}
                onChange={handleAnswer6}
                rows={4}
                style={{ width: 600 }}
            />
            <br/>
            <br/>
            <Button variant="contained" color="secondary" onClick={() => sendToNotes()}>Send to Notes</Button>
            <br/>
            </Grid>
        </div>
    )
}

export default ShortAnswersToNote;