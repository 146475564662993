/* -------------------------------------------------------------------------- */
/*                                NavAppBar.js                                */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                   Extending MUI AppBar for Nav and Logout                  */
/* -------------------------------------------------------------------------- */
import {
  AppBar,
  Toolbar,
  Typography,
  Tooltip,
  IconButton
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import store from '../../../store';
import { logoutUser } from '../../../actions/authActions';
const style = {
  textDecoration: 'none',
  color: '#fff',
  '&:visited': {
    textDecoration: 'none',
    color: '#fff'
  }
};
export default function NavAppBar ({to, toLabel, children}) {
  const handleLogoutUser = e => {
    e.preventDefault();
    store.dispatch(logoutUser());
  }
  //This is for changing the color of the appbar based on the room.
  const currentPage = window.location.pathname;
  const pageStyles = {
    '/roomview': {
      backgroundColor: '#276EF5',
    },
    '/seatingchart': {
      backgroundColor: '#B927F5',
    },
    '/authoring': {
      backgroundColor: '#20BB51'
    },
  };
  const styleForCurrentPage = pageStyles[currentPage] || {};

  return (
    <AppBar
    sx={styleForCurrentPage}>
      <Toolbar>
        <Typography
          variant="h6"
          component="a"
          sx={{
            ml: 2,
            fontWeight: 700,
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          <Link style={style} to='/dashboard'>Return Home</Link>
        </Typography>
        <Typography
          variant="h6"
          sx={{
            ml: 2,
            fontWeight: 700,
            color: 'inherit',
            textDecoration: 'none'
          }}
        >
          |
        </Typography>
        <Typography
          variant="h6"
          component="a"
          sx={{
            ml: 2,
            fontWeight: 700,
            color: 'inherit',
            textDecoration: 'none'
          }}
        >
          <Link style={style} to={to}>{ toLabel }</Link>
        </Typography>
        {/* fake flexgrow to justify icons right*/}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
        {/* tooltips */}
        { children }
        {/* logout */}
        <Tooltip title="Logout">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 1 }}
            onClick={handleLogoutUser}
          >
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  )
}
