import { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { Typography } from '@material-ui/core';
import { Popover } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Draggable from 'react-draggable';
import StarIcon from '@material-ui/icons/Star';
import axios from 'axios';
import store from '../../store';
import socket from '../../utils/socket';
import throttle from "../../utils/Throttle";
import { NoteContext } from '../../reducers/noteContext';
import { FullClassContext } from '../../reducers/fullClassContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  popover: {
    pointerEvents: 'none',
  },
  box: {
    background: '#fff',
    borderWidth: '2px',
    width: '120px',
    height: '80px',
  },
  yupVote: {
    borderColor: '#c0ce6d !important',
  },
  combineVote: {
    borderColor: '#f7e5bc !important'
  },
  noPointerEvents: {
    pointerEvents: 'none'
  }
}));

export const Note = styled.div`
  background-color: #ffffff;
  border: 7px solid ${props => props.groupBorderColor ? props.groupBorderColor : "white"};
  border-radius: 23px;
  box-shadow: 0px 3.23401px 3.23401px 0px rgba(0, 0, 0, 0.25), 0px 3.23401px 3.23401px 0px rgba(0, 0, 0, 0.25);
  height: 146px;
  width: 217px;
  overflow: scroll;
  cursor: grab;
  position: absolute;
  webkit-transition: border 500ms ease-out;
  -moz-transition: border 500ms ease-out;
  -o-transition: border 500ms ease-out;
  transition: border 500ms ease-out;

  & .text-wrapper {
    color: #000000;
    font-family: "Lato-Bold", Helvetica;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    margin-top: 10px;
  }
`

function isCollide(element, container) {
  const brE = element.getBoundingClientRect()
  const brC = container.getBoundingClientRect()

  return !(
    brE.top > brC.bottom ||
    brE.right < brC.left ||
    brE.bottom < brC.top ||
    brE.left > brC.right
  );
}

export default function StudentResponse({
  discussionId,
  room,
  note,
  filter,
  groupBorderColor
}) {
  const classes = useStyles();
  const nodeRef = useRef(null);
  const userId = store.getState().auth.user.id;
  const noteId = note.noteId;
  const [{ noteZindex }, dispatch] = useContext(NoteContext);
  const [, fullDispatch] = useContext(FullClassContext);
  const [state, setState] = useState({
    activeDrags: 0,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [indexState, setIndexState] = useState(3);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [controlledPosition, setControlledPosition] = useState({x: note.x, y: note.y})

  const onControlledDrag = useCallback((position) => {
    const {x, y, localId, vote, activeDrags} = position;
    if (localId === noteId) {
      setControlledPosition({x, y});
      setState({...state, activeDrags});
      dispatch({
        type: 'UPDATE_NOTE_VOTE',
        id: noteId,
        payload: vote
      });
    }
    // eslint-disable-next-line
  }, []);

  const onStart = () => {
    setState({...state, activeDrags: ++state.activeDrags});
  };

  const drawBox = (x, y, vote='', combined=false, activeDrags=0) => {
    const localId = noteId;
    socket.emit(room, {x, y, localId, vote, combined, activeDrags});
  }

  const handleDrag = (e, ui) => {
    e.preventDefault();
    if (!state.activeDrags) { return; }
    drawBox(ui.x, ui.y)
  };

  useEffect(() => {
    socket.on(room, onControlledDrag);
    // eslint-disable-next-line
  }, [room, socket]);

  const onDrop = async(e, ui) => {
    e.preventDefault();
    if (!state.activeDrags) { return }
    const {x, y, node} = ui;
    const yup = document.querySelector(".yup-target");
    const nope = document.querySelector(".nope-target");
    const combine = document.querySelector(".combine-target");
    const ideas = document.querySelectorAll(".idea-target");
    var vote = '';
    var ideaId = '';
    if (yup && isCollide(node, yup)) {
      vote = 'yup';
    }
    if (nope && isCollide(node, nope)) {
      vote = 'nope';
    }
    if (combine && isCollide(node, combine)) {
      vote = 'combine';
    }
    if (ideas) {
      ideas.forEach((idea) => {
        if (isCollide(node, idea)) {
          if (idea.hasAttribute('data-id')) {
            ideaId = idea.getAttribute('data-id');
          }
          e.target.classList.remove('hovered');
        }
      });
    }
    setState({...state, activeDrags: --state.activeDrags});
    setControlledPosition({x, y});
    drawBox(x, y, vote, state.activeDrags);
    // Saving userId will track who's the last user to touch a note
    // Will always default to creator's userId when finalizing
    try {
      await axios.post('/api/notePositions/save', {
        userId,
        noteId,
        discussionId,
        x: x,
        y: y,
        content: note.content,
        tags: note.tags,
        vote,
        ideaId,
        combined: note.combined,
        ingredientIds: note.ingredientIds,
        combinedContent: note.combinedContent,
        originRoom: note.originRoom
      });
      dispatch({
        type: 'UPDATE_NOTE_VOTE',
        id: noteId,
        payload: vote
      });
      fullDispatch({
        type: 'UPDATE_NOTE_IDEAID',
        id: noteId,
        payload: ideaId
      });
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: err
      });
    }
  };

  const stackNote = () => {
    dispatch({
      type: 'NOTE_ZINDEX',
      payload: noteZindex
    });
    setIndexState(noteZindex);
  };

  // notes used in a combination should exist but not rendered
  if (note.combined) {
    return (<></>)
  }

  if ((filter && note.tags.includes(filter)) || !filter) {
    return (
      <Draggable
        nodeRef={nodeRef}
        grid={[25, 25]}
        position={controlledPosition}
        onDrag={throttle(handleDrag, 10)}
        onStart={onStart}
        onStop={onDrop}
        onMouseDown={stackNote}
      >
        <Note
          className={`${note.vote === 'yup' ? classes.yupVote : ''} ${note.ideaId ? classes.yupVote : ''} ${note.vote === 'combine' ? classes.combineVote : ''}`}
          ref={nodeRef}
          groupBorderColor={groupBorderColor}
          style={{ zIndex: indexState, overflow: 'scroll'}}
        >
          <div className="text-wrapper">{note.content}</div>
          { note.tags.length > 0 && <div className="text-wrapper">Tags: {note.tags.join(', ')}</div> }
          { note.ingredientIds.length > 0 &&
            <div>
              <StarIcon
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              />
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none',
                }}
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography>Ingredients: {note.combinedContent}</Typography>
              </Popover>
            </div>
          }
        </Note>
      </Draggable>
    )
  } else {
    return (<></>)
  }
}