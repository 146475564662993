import { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { NoteContext } from '../../reducers/noteContext';
import store from '../../store';
import socket from '../../utils/socket';
import {
  Container,
  Typography,
  AppBar,
  Toolbar,
  Box,
  Stack,
  Button,
  Tooltip,
  IconButton
} from '@mui/material';
import meme1 from '../../assets/Meme1.jpeg';
import meme2 from '../../assets/Meme2.jpeg';
import meme3 from '../../assets/Meme3.jpeg';
import meme4 from '../../assets/Meme4.jpeg';
import meme5 from '../../assets/Meme5.png';
import meme6 from '../../assets/Meme6.png';
import meme7 from '../../assets/Meme7.gif';
import meme8 from '../../assets/Meme8.gif';
import { logoutUser } from "../../actions/authActions";
import LogoutIcon from '@mui/icons-material/Logout';

import './animated-loading.css';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer - 1,
  },
  center: {
    height: '50px',
    width: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  gridFixedBottom: {
    position: 'fixed',
    bottom: '20px',
  },
  loadingButton: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    padding: 2
  }
}));

const room = "/waiting";

// Waiting Room Component
export default function WaitingRoom() {
  const [, dispatch] = useContext(NoteContext);

  const user = store.getState().auth.user;
  const userId = user.id;
  const history = useHistory();
  const classes = useStyles();

  const alertUser = e => {
    e.preventDefault()
    e.returnValue = ''
  };

  const handleAway = async () => {
    socket.emit(`${room}/leave`, userId);
  };

  // Handle back button
  const handleBack = () => {
    // check if state needs to be cleared
    window.removeEventListener('beforeunload', alertUser);
    window.removeEventListener('unload', handleAway);
    history.push('/notebook');
  };

  // Randomly render a meme
  const renderMeme = () => {
    const memeArr=[meme1,meme2,meme3,meme4,meme5,meme6,meme7,meme8];
    const randomIndex=memeArr[(Math.floor(Math.random() * memeArr.length))]
    return randomIndex;
  };

  const handleLogoutUser = e => {
    e.preventDefault();
    store.dispatch(logoutUser());
  }

  useEffect(() => {
    // 403 and refuse socket connection
    // requirement for notebook notes
    if (user.id === undefined) {
      history.push(`/login`);
    } else {
      socket.connect();
      if (user.access === 1) {
        socket.emit(`${room}/join`, { userId, room, name: `${user.first_name} ${user.last_name}` })
      }
    }

    document.title = 'Waiting Room';

    socket.on(`${room}/join`, (arg) => {
      dispatch({
        type: 'SET_ROOM_USERS',
        payload: arg
      });
    });

    socket.on(`${room}/leave`, (arg) => {
      dispatch({
        type: 'REMOVE_ROOM_USER',
        payload: arg
      });
    });

    // Logic for moving user to discussion room
    socket.on(`${room}/move`, (targetUserId, targetDiscussionId, unit) => {
      if (targetUserId === userId) {
        window.removeEventListener('beforeunload', alertUser);
        window.removeEventListener('unload', handleAway);

        dispatch({
          type: 'REMOVE_ROOM_USER',
          payload: targetUserId
        });

        // If no discussion id, go to discussion page
        if (targetDiscussionId === undefined) {
          history.push(`/discussion`)
        } else if (unit) {
          history.push(`/fulldiscussion/note/${unit}/${targetDiscussionId}`)
        } else {
          history.push(`/discussion/${targetDiscussionId}`)
        }
        window.location.reload();
      }
    });
    // Logic for removing user if they navigate away from the page
    // Also refresh notification
    window.addEventListener('beforeunload', alertUser);
    window.addEventListener('unload', handleAway);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('unload', handleAway);
      handleAway();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Group: ???????? | Question Prompt: ????????
          </Typography>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
          <Tooltip title="Logout">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
              onClick={handleLogoutUser}
            >
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Stack spacing={2}>
          <Box
           component="img"
            display="flex"
            alignItems="center"
            sx={{
              height: '400px',
              width: '100%',
            }}
            alt="CAT MEME"
            src={renderMeme()}
          />
          <Button sx={{marginTop: '2em'}}
            variant="contained"
            onClick={handleBack}
          >Go Back to Notebook</Button>
        </Stack>
      </Box>
        <Typography variant="h5" className={classes.loadingButton}>
          <div class="loading">Now Loading</div>
        </Typography>
    </Container>
  )
}