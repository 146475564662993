import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Modal,
  Paper,
  Card,
  CardHeader,
  Grid,
  Tooltip,
  Typography,
  IconButton,
  Badge
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { TextField } from '@mui/material';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import FaceIcon from '@mui/icons-material/Face';
import axios from 'axios';
import './animate-character.css';
import NavAppBar from './common/NavAppBar';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
      transform: 'translate(-50%, -50%)',
    width: 400,
    height: 'auto',
    padding: 4,
  },
  cardHeader: {
     textAlign: 'center',
  },
  noOverLay: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}));

export default function SeatingChart() {

  const groups = ['Corn', 'Green Bean', 'Kale', 'Lettuce', 'Potato', 'Radish', 'Soybean', 'Squash', 'Sunflower']
  const classes = useStyles();

  const [error, setError] = useState(null);

  const [studentInfo, setStudentInfo] = useState([]);

  // move to room modal
  const [openMoveModal, setMoveModalOpen] = useState(false);
  const handleMoveModalOpen = () => setMoveModalOpen(true);
  const handleMoveModalClose = () => setMoveModalOpen(false);
  const [targetDiscussionId, setTargetDiscussionId] = useState('');
  const [seatingConfig, setSeatingConfig] = useState([]);
  const [currentConfig, setCurrentConfig] = useState('');
  const [startingConfig, setStartingConfig] = useState('');

  const [openConfigModal, setConfigModalOpen] = useState(false);
  const handleConfigModalOpen = () => setConfigModalOpen(true);
  const handleConfigModalClose = () => setConfigModalOpen(false);
  const [textboxValue, setTextboxValue] = useState('');

  // selection model
  const [selectionModel, setSelectionModel] = useState([]);
  const handleDeselectAll = () => setSelectionModel([]);


  useEffect(() => {
    document.title = `Seating Chart`;

    (async () => {
      let tempCurrent = ''
      try {
        const res1 = await axios.get('/api/discussionGroups/configurations/full');
        //this orders configuration to newest date on page load
        tempCurrent = res1.data.sort(function(a,b){
          return new Date(b.date) - new Date(a.date);
        });
      } catch (err) { setError(err.response.data) };
      if (tempCurrent.length > 0) {
        setStartingConfig(tempCurrent[0].configuration);
      }
      setCurrentConfig(tempCurrent)

      let tempStudents = [];
      try {
        const res2 = await axios.get('/api/users/getUsersForUserManagement');
        //sets student names as first / last and initial room to waiting
        res2.data.map((user) => {
          let tempRoom = '/waiting'
          const fullName = user.first_name + ' ' + user.last_name;
          tempStudents.push({fullName: fullName, room: tempRoom, id:user._id});

        });
      } catch (err) { setError(err.response.data) };

      //sets current config users to proper rooms
      tempStudents.map((index) => {
        tempCurrent.map((index2) => {
          index2.userIds.map((index3)=> {
            if (index3===index.id) {
              index.room=index2.groupNumber
            };
          });
        });
      });
        setStudentInfo(tempStudents);

      try{
        const res3 = await axios.get('/api/discussionGroups/configurations');
        setSeatingConfig(res3.data);
      } catch (err) { setError(err.response.data) };
    })();
  }, []);


  //Changes display on room card when no students are in room
  function CustomNoRowsOverlay() {
    return (
      <Typography component='div' className={classes.noOverLay}>No students in room</Typography>
    );
  };

  const handleChangeConfig = async (e) => {
    let tempCurrent = ''
    const tempStudent =studentInfo;
    try{
      const discussionGroups = await axios.get('/api/discussionGroups', {
        params: { configuration: e.target.value }
      });
      tempCurrent=discussionGroups.data;
    } catch (err) { setError(err.response.data) };

      tempStudent.map((studentElement, index) => {
        //reset room to waiting before updating rooms based on DB
        tempStudent[index].room = '/waiting';
        tempCurrent.map((currentElement) => {
          let matchedId = currentElement.userIds.filter(f => f === studentElement.id).toString()
          if (matchedId === studentElement.id) {
            tempStudent[index].room = currentElement.groupNumber
          };
        });
      });

      setStudentInfo(tempStudent);
      setCurrentConfig(tempCurrent);
  };

  const createSeatingConfig = async () => {
    Promise.all(groups.map(async index => {
      try {
        await axios.post('/api/discussionGroups/configurations/create', {
          groupNumber: index,
          userIds: [],
          configuration: textboxValue
        });
      } catch (err) { setError(err.response.data) };
    }));

    try {
      const res = await axios.get('/api/discussionGroups/configurations');
      setSeatingConfig(res.data)
    } catch (err) { setError(err.response.data) };

    handleConfigModalClose();
    window.location.reload();

  };


  const handleDeleteConfig = async () => {
    try {
      await axios.post('/api/discussionGroups/configurations/delete', {
        config : currentConfig[0]
      });
    } catch (err) { setError(err.response.data) };

    window.location.reload();

  };

  const handleMove = async () => {
    let studentArr = []
    let updatedStudentInfo = [...studentInfo];
    let updatedConfig = [...currentConfig]
    console.log(currentConfig)
    const configTargetId = currentConfig.find(i => i.groupNumber === targetDiscussionId)._id
    for (let selectedUserId of selectionModel) {
      studentArr.push(selectedUserId);
      const roomToUpdate = updatedStudentInfo.find(i => i.id === selectedUserId);
      if (roomToUpdate) {
        updatedConfig = updatedConfig.filter(i => i.configuration === currentConfig[0].configuration)
        updatedConfig.map((index) => {
          index.userIds = index.userIds.filter(i => i !== selectedUserId)
          if (index._id === configTargetId) {
            index.userIds.push(selectedUserId)
          }
          //make the object match the schema
          delete index.__v;
        })
        updatedStudentInfo.find(i => i.id === selectedUserId).room = targetDiscussionId;
        setStudentInfo(updatedStudentInfo);
      };
    };

    try {
      await axios.post('/api/discussionGroups/configurations/delete', {
        config : currentConfig[0]
      });
    } catch (err) { setError(err.response.data) };

    try {
      await axios.post('/api/discussionGroups/configurations/update', {
        config: updatedConfig
      });
    } catch (err) { setError(err.response.data) };

    setTargetDiscussionId('');
    setSelectionModel([]);
    handleMoveModalClose();
  };

  return (
    <Container maxWidth="xl">
      <NavAppBar
        to='/roomview'
        toLabel='Go To Room View'
      >
          {/* fake flexgrow to justify icons right*/}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
          { selectionModel.length > 0 && <>
          <Tooltip title="Move Selected">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
              onClick={() => handleMoveModalOpen()}
              disabled={currentConfig.length === 0}
            >
              <TransferWithinAStationIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Deselect All">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
              onClick={() => handleDeselectAll()}
            >
              <RemoveDoneIcon />
            </IconButton>
          </Tooltip>
          </> }
          <Tooltip title="Number of Selected Students" sx={{mr:2}}>
            <Badge color="secondary" badgeContent={selectionModel.length} showZero>
              <FaceIcon />
            </Badge>
          </Tooltip>
          <Tooltip title="Create New Seating Chart">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
              onClick={() => handleConfigModalOpen()}
            >
              <GroupAddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Current Seating Chart">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
              onClick={() => handleDeleteConfig()}
            >
              <PlaylistRemoveIcon />
            </IconButton>
          </Tooltip>
        </NavAppBar>
      <Grid container spacing={1} style = {{ marginTop: 75 }}>
        <Grid container item spacing={2} sm={12} lg={3} xl={3} sx={{ pr: 2, height: '75%'}}>
          <Grid item xs={12} md={12} xl={12} sx = {{ height: '10%', mb: { sm: 0, xl: 10}}}>
            { seatingConfig.length > 0  && startingConfig && <Card sx={{  border : 3, borderColor:'primary.main', borderRadius: '10px'}}>
              <FormControl fullWidth variant="filled" >
                <InputLabel id="configuration-select-label">Current Group Seating Chart</InputLabel>
                <Select
                  labelId="configuration-select-label"
                  id="configuration-select"
                  value={seatingConfig.name}
                  defaultValue={startingConfig}
                  onChange={(e) => handleChangeConfig(e)}
                >
                { seatingConfig.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    <Typography variant='inherit' noWrap>{name}</Typography>
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
            </Card>}
          </Grid>
          <Grid item xs={12} md={12} xl={12} sx={{ height: '65%'}} >
            <Card sx={{  border : 3, borderColor:'primary.main', borderRadius: '10px'}}>
              <CardHeader sx={{  borderBottom : 3, borderColor:'primary.main', borderRadius: '5px'}}
                className={classes.cardHeader}
                component={'div'}
                title={'Unassigned Students'}
              />
              <Typography component="div" style={{ height: 500, width: "100%" }}>
                { studentInfo && <DataGrid
                  rows={studentInfo.filter(f => f.room === '/waiting')}
                  columns={[{ field: 'fullName', headerName: 'Name', width: 250 }]}
                  checkboxSelection
                  getRowId={(r) => r.id}
                    onSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                  }}
                  selectionModel={selectionModel}
                  keepNonExistentRowsSelected
                  disableSelectionOnClick
                  hideFooterRowCount
                  hideFooter
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay
                  }}
                /> }
              </Typography>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={3} item sm={12} lg={9} xl={9}>
          {groups.map((index, i) =>
            <Grid key={index} item xs={6} sm={6} md={6} lg={4} xl={4}>
              <Card sx={{  border: 3, borderColor:'primary.main', borderRadius: '10px'}}>
                <Card sx={{  height: 95, borderBottom: 3, borderColor:'primary.main', borderRadius: '5px', backgroundColor: 'defaultBgcolor' }}>
                  <Grid container spacing={1} xl={12}>
                    <Grid item xs={6} md={6} xl={6}>
                      <Typography sx={{ fontSize: {sm:20, md:20, lg:18, xl:24}, mt:1, mb:1, ml:3 }}>
                        {groups[i]}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
                <div style={{ height: 250, width: "100%" }}>
                  {studentInfo && <DataGrid
                    rows={studentInfo.filter(f => f.room === groups[i])}
                    columns={[{ field: 'fullName', headerName: 'Name', width: 250 }]}
                    checkboxSelection
                    getRowId={(r) => r.id}
                    onSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    keepNonExistentRowsSelected
                    disableSelectionOnClick
                    hideFooter
                    components={{
                      NoRowsOverlay: CustomNoRowsOverlay
                    }}>
                  </DataGrid>}
                </div>
              </Card>
          </Grid>)}
        </Grid>
      </Grid>
      <Modal
        open={openMoveModal}
        onClose={handleMoveModalClose}
        aria-labelledby="move-modal-title"
        aria-describedby="move-modal-description"
      >
        <div className={classes.modal}>
          <Paper className={classes.paper}>
            <Grid container spacing={5}>
              <Grid item xs={12} md={12} xl={12}>
                <Typography variant='h4'>
                  Move student(s) into groups
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Typography variant='subtitle1'>
                  {selectionModel.length} student(s) currently selected
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="move-select-label">select group</InputLabel>
                  <Select
                    labelId="move-select-label"
                    id="move-select"
                    label="Move Rooms"
                    value={targetDiscussionId}
                    onChange={(e) => setTargetDiscussionId(e.target.value)}
                  >
                    { groups.map((value, index) => {
                      return(
                        <MenuItem key={index} value={value} className={classes.menuItem}>
                          {value}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleMove()}
                >send to group
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Modal>
      <Modal
        open={openConfigModal}
        onClose={handleConfigModalClose}
        aria-labelledby="configuration-modal-title"
        aria-describedby="configuation-modal-description"
      >
        <div className={classes.modal}>
          <Paper className={classes.paper}>
            <Grid container spacing={5}>
              <Grid item xs={12} md={12} xl={12}>
                <Typography variant='h4'>
                  Create New Seating Chart
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <TextField
                  id="new-configuration"
                  label="Seating Chart Name"
                  variant="standard"
                  value={textboxValue}
                  onChange={(event)=> setTextboxValue(event.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => createSeatingConfig()}
                >Create Seating Chart
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Modal>
    </Container>
  )
}